import React from 'react';

const ButtonController = ({add, update, del, selected}) => {
  if(selected) {
    return (
    <div className="row">

      <div className="col-12 col-lg-6">
        <button onClick={update} className="btn btn-success btn-block">
          Обновить
        </button>
      </div>

      <div className="col-12 col-lg-6">
        <button onClick={del} className="btn btn-danger btn-block">
          Удалить
        </button>
      </div>

    </div>
    )
  }

  return (
    <button onClick={add} className="btn btn-success btn-block">
      Добавить 
    </button>
  );
}

export default ButtonController;
