const LOCALIZEDB_TOGGLE = 'LOCALIZEDB_TOGGLE';
function localizedb_toggle() {
  return {
    type: LOCALIZEDB_TOGGLE
  }
}

const MODEL_LOCALIZE_SET_MARKA_CONF = 'MODEL_LOCALIZE_SET_MARKA_CONF';
function model_localize_set_marka_conf(marka_id, conf) {
  return {
    type: MODEL_LOCALIZE_SET_MARKA_CONF,
    marka_id,
    conf 
  }
}

const MODEL_LOCALIZE_SELECT = 'MODEL_LOCALIZE_SELECT';
function model_localize_select(marka_id, marka) {
  return {
    type: MODEL_LOCALIZE_SELECT,
    marka_id,
    marka,
  }
}

const MODEL_LOCALIZE_ON_CHANGE_MODEL = 'MODEL_LOCALIZE_ON_CHANGE_MODEL';
function model_localize_on_change_model(keyModel, key, value) {
  return {
    type: MODEL_LOCALIZE_ON_CHANGE_MODEL,
    keyModel,
    key,
    value
  }
}

const MODEL_LOCALIZE_ON_CHANGE_MODELS = 'MODEL_LOCALIZE_ON_CHANGE_MODELS';
function model_localize_on_change_models(models) {
  return {
    type: MODEL_LOCALIZE_ON_CHANGE_MODELS,
    models
  }
}

const MODEL_LOCALIZE_ON_CHANGE = 'LOCALIZE_ON_CHANGE';
function model_localize_on_change(path, key, value) {
  return {
    type: MODEL_LOCALIZE_ON_CHANGE,
    path,
    key,
    value
  }
}

const MODEL_LOCALIZE_ON_CHACKED = 'MODEL_LOCALIZE_ON_CHACKED';
function model_localize_on_chacked(key) {
  return {
    type: MODEL_LOCALIZE_ON_CHACKED,
    key
  }
}

MODEL_LOCALIZE_COMANDS = {
  delete_model: 'Удалить',
  delete_model_all: 'Удалить все',
  add_group: 'Добавить группу',
  move_group: 'Переместить в группу',
  delete_group: 'Удалить группу',
  on_change_group: 'Изминить наименование группы',
}

const MODEL_LOCALIZE_EXECUTE_COMAND = 'MODEL_LOCALIZE_EXECUTE_COMAND';
function model_localize_execute_comand(comand, keys, params) {
  return {
    type: MODEL_LOCALIZE_EXECUTE_COMAND,
    keys,
    comand,
    params
  }
}

function wraper_model_localize_execute_comand(comand, keys, params) {
  return function (dispatch, getState) {
    dispatch(model_localize_execute_comand(comand, keys, params));

    if(comand === 'move_group') {
      const { localizeMarka }  = getState();

      let models = keys
        .reduce((obj, key) => {
          obj[key] = localizeMarka.marka.conf.model[key];
          return obj;
        }, {});

      dispatch(model_localize_on_change_models(models));
    }

    if(comand === 'on_change_group') {
      const { localizeMarka }  = getState();

      let models = {}
      for(let key in localizeMarka.marka.conf.model) {
        if(localizeMarka.marka.conf.model[key]['group_id'] === params.group_name) {
          models[key] = {
            ...localizeMarka.marka.conf.model[key],
            group_name: params.new_group_name
          }
        }
      }

      console.log('on_change_group: ', models);
      dispatch(model_localize_on_change_models(models));
    }

    if(comand === 'delete_group') {
      const { localizeMarka }  = getState();

      let models = {}
      for(let key in localizeMarka.marka.conf.model) {
        if(localizeMarka.marka.conf.model[key]['group_id'] === params.group_name) {
          models[key] = {
            ...localizeMarka.marka.conf.model[key],
            group_name: 'no group',
            group_id: 0
          }
        }
      }

      console.log('Delete_group: ', models);
      dispatch(model_localize_on_change_models(models));
    }

  }
}

const MODEL_LOCALIZE_CONF_LOCALIZE_ONCHANGE = 'MODEL_LOCALIZE_CONF_LOCALIZE_ONCHANGE';
function model_localize_conf_localize_onchange(key, value) {
  return {
    type:MODEL_LOCALIZE_CONF_LOCALIZE_ONCHANGE,
    key,
    value
  }
}
const MODEL_LOCALIZE_CONF_LOCALIZE_DELETE = 'MODEL_LOCALIZE_CONF_LOCALIZE_DELETE';
function model_localize_conf_localize_delete(key) {
  return {
    type:MODEL_LOCALIZE_CONF_LOCALIZE_DELETE,
    key
  }
}

const MODEL_LOCALIZE_CONF_LOCALIZE_ONCHANGE_KEY = 'MODEL_LOCALIZE_CONF_LOCALIZE_ONCHANGE_KEY';
function model_localize_conf_localize_onchange_key(key, keyNext) {
  return {
    type:MODEL_LOCALIZE_CONF_LOCALIZE_ONCHANGE_KEY,
    key,
    keyNext,
  }
}


const MODEL_LOCALIZE_CONF_REPLACE_ONCHANGE = 'MODEL_LOCALIZE_CONF_REPLACE_ONCHANGE';
function model_localize_conf_replace_onchange(id, value) {
  return {
    type: MODEL_LOCALIZE_CONF_REPLACE_ONCHANGE,
    value, 
    id
  }
}

const MODEL_LOCALIZE_CONF_REPLACE_DELETE = 'MODEL_LOCALIZE_CONF_REPLACE_DELETE';
function model_localize_conf_replace_delete(value) {
  return {
    type: MODEL_LOCALIZE_CONF_REPLACE_DELETE,
    value 
  }
}

const MODEL_LOCALIZE_CONF_REPLACE_ADD = 'MODEL_LOCALIZE_CONF_REPLACE_ADD';
function model_localize_conf_replace_add(value) {
  return {
    type: MODEL_LOCALIZE_CONF_REPLACE_ADD,
    value
  }
}

module.exports = {
  MODEL_LOCALIZE_CONF_LOCALIZE_ONCHANGE,
  model_localize_conf_localize_onchange,
  MODEL_LOCALIZE_CONF_LOCALIZE_DELETE,
  model_localize_conf_localize_delete,
  MODEL_LOCALIZE_CONF_LOCALIZE_ONCHANGE_KEY,
  model_localize_conf_localize_onchange_key,

  MODEL_LOCALIZE_CONF_REPLACE_ADD,
  model_localize_conf_replace_add,
  MODEL_LOCALIZE_CONF_REPLACE_ONCHANGE,
  model_localize_conf_replace_onchange,
  MODEL_LOCALIZE_CONF_REPLACE_DELETE,
  model_localize_conf_replace_delete,

  MODEL_LOCALIZE_COMANDS,
  MODEL_LOCALIZE_EXECUTE_COMAND,
  model_localize_execute_comand: wraper_model_localize_execute_comand,

  MODEL_LOCALIZE_ON_CHACKED,
  model_localize_on_chacked,

  MODEL_LOCALIZE_ON_CHANGE,
  model_localize_on_change,

  MODEL_LOCALIZE_ON_CHANGE_MODEL,
  model_localize_on_change_model,
  MODEL_LOCALIZE_ON_CHANGE_MODELS,
  model_localize_on_change_models,

  MODEL_LOCALIZE_SELECT,
  model_localize_select,

  MODEL_LOCALIZE_SET_MARKA_CONF,
  model_localize_set_marka_conf,

  LOCALIZEDB_TOGGLE,
  localizedb_toggle,
}
