import React from 'react';
import { connect } from 'react-redux';
import { actionAuthLogout } from '../actions';

function SignOut(props) {
  const style = (props.isAuth) ? null : {display: 'none'};

  return(
    <button
      className="btn btn-outline-danger"
      style={style}
      onClick={props.signOut}
    >Sign Out</button>
  )
}

const mapStateToProps = (state) => {
  return {
    isAuth: state.auth.isAuth
  }
}

const mapDispathToProps = (dispath) => {
  return {
    signOut: () => {
      dispath(actionAuthLogout())
    }
  }
}


export default connect(mapStateToProps, mapDispathToProps)(SignOut);
