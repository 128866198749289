import React from 'react';
import {connect} from 'react-redux';
import { api, apiFetch, apiInvalidate } from '../api';
import { Link } from 'react-router-dom'


const ButtonRuninng = ({running, run, stop}) => {
  if(running) {
    return (
      <button onClick={stop} className="btn btn-outline-danger">
        Остановить
      </button>
    );
  }

  return (
    <button onClick={run} className="btn btn-outline-success">
      Запустить 
    </button>
  );
}

const ProgressBar = ({title, min, max}) => {
  const current = max - min;
  const progress = (max) ? min * 100 / max : 0;
  return(
    <div className="mt-3">
      <p style={{"margin-bottom": 0}}>{title}</p>
      <div className="progress">
        <div className="progress-bar"
          role="progressbar"
          style={{width: `${progress}%`}}
          aria-valuenow={min}
          aria-valuemin="0"
          aria-valuemax={max}>{`${min} / ${max}`}</div>
      </div>
    </div>
  );
} 

class Parser extends React.Component {

  render() {
    const{parser, run, stop} = this.props;
    const {running, name, stat, datestart, datestop} = parser;
    const marka = {
      title: 'Марка:',
      min: 0,
      max: 0,
    }
    const queue = {
      title: 'Марки в очереди',
      min: 0,
      max: 0,
    }

    if(stat.status) {
      marka.title = `Марка: ${stat.markaName}`; 
      marka.max = stat.markaMax;
      marka.min = stat.markaMin;

      queue.max = stat.queueMax;
      queue.min = stat.queueMin;
    }

    return (
      <div className="col-sm-6">
        <div className="card">

          <Link className="card-header"
          to={`/monitoring/${name}`}>{name}</Link>
          <div className="card-body">

          <div className="row">
            <div className="col">
              <ButtonRuninng
                stop={() => stop({name})}
                run={() => run({name})}
                running={running}
              />
            </div>
            <div className="col">
              <p>Запущен: {datestart}</p>
              <p>Остановлен: {datestop}</p>
            </div>
          </div>
            <ProgressBar
            {...marka}
            />
            <ProgressBar
            {...queue}
            />
          </div>
        </div>
      </div>
    );
  }
}

const ParserWraper = ({items, run, stop}) => {
  const parsers = items.map(parser => <Parser run={run} stop={stop} parser={parser}/>);
  return (
    <div className="row mb-3">
      {parsers}
    </div>
  );
}


class Monitoring extends React.Component {

  constructor(props) {
    super(props);
    this.timerId = null;
  }
  
  componentDidMount() {
    this.props.onInvalidate('parser');
    this.timerId = setInterval(() => this.props.onInvalidate('parser'), 10000);
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  render() {
    const {run, stop} = this.props;

    this.props.items.sort((a, b) => {
      if(a.name > b.name) return 1; 
      if(a.name < b.name) return -1; 
    });
    
    let parsers = this.props.items.reduce((result, el) => {
      if(!result.length) {
        return result.push([el]), result;
      }

      const current = result[result.length - 1];

      if(current.length < 2) {
        return current.push(el), result;
      }

      if(current.length === 2) {
        return result.push([el]), result;
      }
    }, [])
    .map(el => <ParserWraper stop={stop} run={run} items={el}/>);

    return (
      <div>
        {parsers} 
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.api.models.parser.isLoading,
    hasErrored: state.api.models.parser.hasErrored,
    items: state.api.models.parser.items,
    didInvalidate: state.api.models.parser.didInvalidate,
  }
}

const mapDispathToProps = (dispatch) => {
  return {
    onInvalidate: (name) => dispatch( apiInvalidate(name) ),
    run: (data) => dispatch( apiFetch(api.runParser, data, 'parser')),
    stop: (data) => dispatch( apiFetch(api.stopParser, data, 'parser'))
  }
}

export default connect(mapStateToProps, mapDispathToProps)(Monitoring);
