const { PAGE_ON_CHANGE } = require('./actions'); 
const {FORMS_SET_UPDATE, FORMS_ON_SET} = require('../forms'); 
console.log(FORMS_SET_UPDATE, 'form');

const initPage = {
  template: {
    actionInputSelect: false,
    selectedTemplate: null
  },
  parser: {
    item: 0
  },
  localizeMarka: {
    showGroup: "no group",
    selectComands: null,
    inputNewGroups: null,
    actionInputSelectMarka: false,
    selectedInputSelectMarka: null
  },
  clients: {
    selectedClients: null
  }
}

function field(state={}, action) {
  switch(action.type) {
    case PAGE_ON_CHANGE:
    case FORMS_SET_UPDATE:
      return {
        ...state,
        [action.name]: (state.active === action.value) ?
        null : action.value
      }
    default:
      return state;
  }
}

function page(state=initPage, action) {
  console.log('pageOnChange: ', action);
  switch(action.type) {
    case PAGE_ON_CHANGE:
    case FORMS_SET_UPDATE:
      return {
        ...state,
        [action.fieldName]: field(state[action.fieldName], action) 
      }
    default:
      return state;
  }
}

module.exports = page;
