import React from 'react';

export default class ModelComands extends React.Component {
  constructor(props) {
    super(props);
    this.onChangeComands = this.onChangeComands.bind(this);
    this.handleOnExecute = this.handleOnExecute.bind(this);
  }

  onChangeComands(event) {
    this.props.onChange(
      'selectComands',
      event.target.value,
    );
  }

  handleOnExecute() {
    const options = {};

    switch(this.props.selectComand) {
      case 'add_group':
      case 'delete_group':
      case 'move_group':
        options['group_name'] = this.props
          .page[this.props.selectComand]
        break;
      case 'on_change_group':
        options['group_name'] = this.props
          .page[this.props.selectComand]
        options['new_group_name'] = this.props
          .page[this.props.selectComand + "_GroupName"]

    }

    this.props.onExecute(options);
  } 

  render() {
    const { comands, selectComand } = this.props;
    const options = [];

    for(let key in comands) {
      options.push(<option value={key}>{comands[key]}</option>)
    }

    return (
      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <label className="input-group-text">Команда</label>
        </div>
        <select value={selectComand} onChange={this.onChangeComands} className="custom-select">
          <option selected>Choose...</option>
          {options}
        </select>
        <div className="input-group-append">
          <button className="btn btn-outline-secondary" type="button"onClick={this.handleOnExecute}>Выполнить</button>
        </div> 
      </div>
    );
  }
}
