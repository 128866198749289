import React from 'react';
import InputGroup from './input.jsx';

const Form = ({ onChange, keys, item={}, exclude=[] }) => {
  const elems = keys
    .filter(key => !exclude.includes(key))
    .map((key, id) => {
    return (
      <InputGroup
        onChange={onChange}
        key={id}
        label={key}
        value={item[key] || ""}
      />
    )
  });
  const pin = Math.ceil(elems.length/2);

  return (
      <div className="row">
        <div className="col-12 col-lg-6 ">{elems.slice(0, pin)}</div>
        <div className="col-12 col-lg-6">{elems.slice(pin)}</div>
      </div> 
  )
}


export default Form;
