import React from 'react';

export default ({handleSubmit, handleChange} = props) => {
  return (
  <form className="form-signin mt-3" onSubmit={handleSubmit}>
    <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>
    <label for="inputEmail" className="sr-only">Email address</label>
    <input onChange={handleChange} name="userName" type="text" id="inputEmail" className="form-control" placeholder="Email address" required autofocus="true"/>
    <label for="inputPassword" className="sr-only">Password</label>
    <input onChange={handleChange} name="password" type="password" id="inputPassword" className="form-control" placeholder="Password" required/>

    <div className="checkbox mt-1 mb-3">
      <label>
        <input onChange={handleChange} name="rememberMe" type="checkbox" value="remember-me"/> Remember me
      </label>
    </div>

    <button className="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
    <p className="mt-5 mb-3 text-muted">&copy; 2017-2018</p>
  </form>
  );
}
