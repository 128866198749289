import React from 'react';
import { connect } from 'react-redux';
import { api, apiFetch } from '../../api';

class AddParser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      url: ''
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    this.props.add(this.state);
  }

  handleChange(event) {
    let
      target = event.target,
      name = target.name,
      value = target.value;

    this.setState({[name]: value}); 
  }

  render() {
    if (this.props.isLoading) {
      return <div>Loadding...</div>
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-group">
          <label htmlFor="parserName">Имя нового парсера</label>
          <input type="text" name="name"
            className="form-control"
            id="parserName"
            onChange={this.handleChange}
            placeholder="name new parser"
            required autofocus="true"
          />
        </div>
        <div className="form-group">
          <label htmlFor="parserUrl">Адрес нового парсера</label>
          <input type="url" name="url"
            className="form-control"
            id="parserUrl"
            onChange={this.handleChange}
            placeholder="address new parser" required
          />
        </div>
        <button className="btn btn-primary">Зарегистрировать</button>
      </form>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    isLoading: state.api.models.parser.isLoading,
  }
}

const mapDispathToProps = (dispatch) => {
  return {
    add: (data) => dispatch( apiFetch(api.add, data, 'parser') ) 
  }
}

export default connect(mapStateToProps, mapDispathToProps)(AddParser);
