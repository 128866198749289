import React from 'react';

export default (props) => {
  var className = 'col';

  if(props.em) {
    className += ` col-${props.em}`;
  }

  if(props.lg) {
    className += ` col-lg-${props.lg}`;
  } 

  if(props.className) {
    className += ` ${props.className}`;
  }

  return (<div className={className}>{props.children}</div>);
}
