import React from 'react';

const Button = ({onClick, name, className, title}) => {
  className = (className) ? "btn btn-block " + className : "btn btn-block btn-outline-primary";
  ;

  return (
    <div className="col-12 col-lg-3">
      <button onClick={onClick} className={className} title={title}>
        {name} 
      </button>
    </div>
  );
}

const ButtonControl = ({onUpdate, onSave, onDownload, onSaveFrom}) => {
  return(
    <div className="mb-3 row">
      <Button name="Загрузить из файла" onClick={onSaveFrom}/>
      <Button name="Выгрузить в файл" onClick={onDownload}/>
      <Button title="Отменяет все изменения, загружает conf с сервера" name="Обновить" onClick={onUpdate}/>
      <Button title="Сохраняет текущий conf на сервер" name="Сохранить"
        className="btn-outline-success"
        onClick={onSave}
      />
    </div>
  );
}

export default ButtonControl;
