const {
  AUTH_QUERY,
  AUTH_SUCCESS,
  AUTH_FAIL,
  AUTH_LOGOUT
} = require('./actions');

const auth = {
  isQuery: false,
  isAuth: false,
  token: null,
  rememberMe: false
}

function reducerAuth(state, action) {
  if(typeof state === 'undefined') {
    return auth;
  }

  switch(action.type) {
    case AUTH_QUERY:
      return {
        ...state,
        isQuery: true
      }
    case AUTH_SUCCESS:
      return {
        ...state,
        isQuery: false,
        isAuth: true,
        rememberMe: action.rememberMe,
        token: action.token 
      }
    case AUTH_FAIL:
      return {
        ...state,
        isQuery: false,
        isAuth: false,
        token: null 
      }
    case AUTH_LOGOUT:
      return {
        ...state,
        isAuth: false,
        token: null 
      }
  }

  return state;
}

module.exports = reducerAuth;
