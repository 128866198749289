const PARSER_CONFIGURE_SET_VALUE = 'PARSER_CONFIGURE_SET_VALUE';
function parser_configure_set_value(parser) {
  return {
    type: PARSER_CONFIGURE_SET_VALUE,
    parser, 
  }
}

const PARSER_CONFIGURE_DID_INVALIDATE = 'PARSER_CONFIGURE_DID_INVALIDATE';
function parser_configure_did_invalidate() {
  return {
    type: PARSER_CONFIGURE_DID_INVALIDATE, 
  }
}

const PARSER_CONFIGURE_SELECT = 'PARSER_CONFIGURE_SELECT';
function parser_configure_select(parserName) {
  return {
    type: PARSER_CONFIGURE_SELECT,
    parserName, 
  }
}

const PARSER_CONFIGURE_SELECT_UPDATE = 'PARSER_CONFIGURE_SELECT_UPDATE';
function parser_configure_select_update(selectParserWhere) {
  return {
    type: PARSER_CONFIGURE_SELECT_UPDATE,
    selectParserWhere
  }
}

const PARSER_CONFIGURE_ON_CHANGE = 'PARSER_CONFIGURE_ON_CHANGE';
function parser_configure_on_change(name, value) {
  return {
    type: PARSER_CONFIGURE_ON_CHANGE,
    name,
    value
  }
}

const PARSER_CONFIGURE_CHECKED_SET = 'PARSER_CONFIGURE_CHECKED_SET';
function parser_configure_checked_set(name) {
  return {
    type: PARSER_CONFIGURE_CHECKED_SET, 
    name
  }
}

module.exports = {
  PARSER_CONFIGURE_SET_VALUE,
  parser_configure_set_value,

  PARSER_CONFIGURE_DID_INVALIDATE,
  parser_configure_did_invalidate,

  PARSER_CONFIGURE_SELECT,
  parser_configure_select,

  PARSER_CONFIGURE_SELECT_UPDATE,
  parser_configure_select_update,

  PARSER_CONFIGURE_ON_CHANGE,
  parser_configure_on_change,

  PARSER_CONFIGURE_CHECKED_SET,
  parser_configure_checked_set
}
