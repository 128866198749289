import React from 'react';
import Input from './input.jsx';

const GroupElModel = ({keyModel, id, name, onChange, onCheked, checked}) => {
  return (
    <div className="row">
      <div className="col-12 col-lg-1 form-check">
        <input
          className={"form-control"}
          type="checkbox"
          checked={checked}
          onChange={() => onCheked(keyModel)}
        />
      </div>
      <Input 
        key={keyModel}
        className="col-12 col-lg-4"
        placeholder="сырая строка"
        value={keyModel}
      />
      <Input
        name="model_id"
        className="col-12 col-lg-3"
        placeholder="id модели"
        value={id}
        onChange={onChange(keyModel)}
      />
      <Input
        name="model_name"
        className="col-12 col-lg-4"
        placeholder="name модели" 
        value={name}
        onChange={onChange(keyModel)}
      />
    </div>
  );
}
export default GroupElModel;
