export function setStateInLocalStore(state) {
  if(!window.localStorage) {
    return;
  }

  try {
    localStorage.setItem('state', JSON.stringify(state));
  } catch(err) {
    console.log('setStateInLocalStore: ', err);
  }
}

export function getStateOfLocalStore() {
  if (!window.localStorage
  && !window.localStorage.length) {
    return undefined;
  }

  const state = window.localStorage['state'];

  if (!state) {
    return undefined;
  }

  return JSON.parse(state);
}

export function deleteStateOfLocalStore() {
  if (!window.localStorage) {
    return false;
  }

  return delete window.localStore.state;
} 
export function trackStorForLocalStore(store) {
  store.subscribe(() => {
    setStateInLocalStore(store.getState());
  })
}
