const { combineReducers } = require('redux');

const {
  LOCALIZE_FETCH_INVALIDATE,
  LOCALIZE_FETCH_REQUEST,
  LOCALIZE_FETCH_RESPONSE,
  LOCALIZE_FETCH_ERROR,
} = require('./fetch');

const { 
  LOCALIZEDB_TOGGLE,
  MODEL_LOCALIZE_COMANDS,
  MODEL_LOCALIZE_EXECUTE_COMAND,
  MODEL_LOCALIZE_ON_CHACKED,
  MODEL_LOCALIZE_ON_CHANGE,
  MODEL_LOCALIZE_ON_CHANGE_MODEL,
  MODEL_LOCALIZE_SELECT,
  MODEL_LOCALIZE_SET_MARKA_CONF,
  
  MODEL_LOCALIZE_CONF_LOCALIZE_ONCHANGE_KEY,
  MODEL_LOCALIZE_CONF_LOCALIZE_ONCHANGE,
  MODEL_LOCALIZE_CONF_LOCALIZE_DELETE,
  MODEL_LOCALIZE_CONF_REPLACE_ADD,
  MODEL_LOCALIZE_CONF_REPLACE_ONCHANGE,
  MODEL_LOCALIZE_CONF_REPLACE_DELETE,
  MODEL_LOCALIZE_ON_CHANGE_MODELS
} = require('./actions');

const init = {
  localizeDB: false,
  selectMarka: null,

  markaList: {
    items: [],
    loading: false,
    error: false,
    didInvalidate: true,
  },

  marka: {
    conf: {},
    loading: false,
    error: false,
    didInvalidate: true,
  },
  changedModel: {
  },

  chekedModel: []
}

function changedModel(state=init.changedModel, action, test) {
  switch(action.type) {
    case LOCALIZE_FETCH_INVALIDATE:
      if(action.select !== 'updateLocalizeConf') return state;
      return{
      }
    case LOCALIZE_FETCH_RESPONSE:
      switch(action.select) {
        case 'updateLocalizeConf':
        case 'marka':
          return {};
        default:
          return state;
      }
    case LOCALIZE_FETCH_REQUEST:
    case LOCALIZE_FETCH_ERROR:
      return state;
    case MODEL_LOCALIZE_SET_MARKA_CONF:
      return {}
    case MODEL_LOCALIZE_ON_CHANGE_MODEL:
      return {
        ...state,
        [action.keyModel]: {
          ...state[action.keyModel],
          [action.key]: action.value
        }
      }
    case MODEL_LOCALIZE_ON_CHANGE_MODELS:
      return {
        ...state,
        ...action.models
      }
    case MODEL_LOCALIZE_EXECUTE_COMAND:
      if(action.hasOwnProperty('comand') && action.comand === 'delete_model') {
        let model_deletes = action.keys.reduce((o, el) => (o[el] = 'delete', o), {});
        return {
          ...state,
          ...model_deletes
        }
      }
      if(action.hasOwnProperty('comand') && action.comand === 'delete_model_all') {
        return {
          ...state,
          'all': 'delete'
        }
      }
    default:
    return state;
  }
}

function selectMarka(state=init.selectMarka, action) {
  switch(action.type) {
    case LOCALIZE_FETCH_RESPONSE:
      if(action.select === 'markaList' && state === null) return action.items[0].marka_id;
      return state
    case MODEL_LOCALIZE_SELECT:
    case MODEL_LOCALIZE_SET_MARKA_CONF:
      return action.marka_id;
    default:
      return state;
  }
}

function markaList(state=init.markaList, action) {
  switch(action.type) {
    case LOCALIZE_FETCH_INVALIDATE:
      if(action.select !== 'markaList') return state;
      return{
        ...state,
        didInvalidate: true
      }
    case LOCALIZE_FETCH_REQUEST:
      if(action.select !== 'markaList') return state;
      return{
        ...state,
        loading: true,
        didInvalidate: false
      }
    case LOCALIZE_FETCH_RESPONSE:
      if(action.select !== 'markaList') return state;
      return{
        ...state,
        loading: false,
        error: false,
        didInvalidate: false,
        items: action.items
      }
    case LOCALIZE_FETCH_ERROR:
      if(action.select !== 'markaList') return state;
      return{
        ...state,
        error: true,
      }
    default:
      return state;
  }
}

function marka(state=init.marka, action) {
  switch(action.type) {
    case LOCALIZE_FETCH_INVALIDATE:
      if(action.select !== 'marka') return state;
      return{
        ...state,
        didInvalidate: true
      }
    case LOCALIZE_FETCH_REQUEST:
      if(action.select !== 'marka') return state;
      return{
        ...state,
        loading: true,
        didInvalidate: false
      }
    case LOCALIZE_FETCH_RESPONSE:
      switch(action.select) {
        case 'updateLocalizeConf':
          return {
            ...state,
            didInvalidate: true,
          }
        case 'marka':
          return{
            ...state,
            loading: false,
            error: false,
            didInvalidate: false,
            conf: action.items[0].conf
          }
        default:
          return state;
      }
    case LOCALIZE_FETCH_ERROR:
      if(action.select !== 'marka') return state;
      return{
        ...state,
        error: true,
      }
    case MODEL_LOCALIZE_SELECT:
      return {
        ...state,
        didInvalidate: true
      };

    case MODEL_LOCALIZE_ON_CHANGE:
      return {
        ...state,
        conf: {
          ...state.conf,
          [action.key]: action.value
        }
      }

    case MODEL_LOCALIZE_SET_MARKA_CONF:
      return {
        ...state,
        conf: action.conf
      }
//    case MODEL_LOCALIZE_ON_CHANGE_MODEL:
//      return {
//        ...state,
//        conf: {
//          ...state.conf,
//          model: {
//            ...state.conf.model,
//            [action.keyModel]: {
//              ...state.conf.model[action.keyModel],
//              [action.key]: action.value
//            }
//          }
//        }
//      }
      //
    case MODEL_LOCALIZE_CONF_REPLACE_ADD: {
      return {
        ...state,
        conf: {
          ...state.conf,
          replaces: [
            ...state.conf.replaces,
            action.value 
          ]
          
        }
      }
    }
    case MODEL_LOCALIZE_CONF_REPLACE_ONCHANGE: {
      state.conf.replaces[action.id] = action.value;

      return {
        ...state,
        conf: {
          ...state.conf,
          replaces: [
            ...state.conf.replaces
          ] 
          
        }
      }
    }
    case MODEL_LOCALIZE_CONF_REPLACE_DELETE: {
      return {
        ...state,
        conf: {
          ...state.conf,
          replaces: state.conf.replaces.filter(el => el !== action.value)
        }
      }
    }
    case MODEL_LOCALIZE_CONF_LOCALIZE_ONCHANGE: {
      return {
        ...state,
        conf: {
          ...state.conf,
          localize: {
            ...state.conf.localize,
            [action.key]: action.value
          }
        }
      }
    }
    case MODEL_LOCALIZE_CONF_LOCALIZE_ONCHANGE_KEY: {
      const value = state.conf.localize[action.key];
      delete state.conf.localize[action.key];

      return {
        ...state,
        conf: {
          ...state.conf,
          localize: {
            ...state.conf.localize,
            [action.keyNext]: value
          }
        }
      }
    }
    case MODEL_LOCALIZE_CONF_LOCALIZE_DELETE: {
      delete state.conf.localize[action.key];

      return {
        ...state,
      } 
    }
    case MODEL_LOCALIZE_EXECUTE_COMAND:
      return {
        ...state,
        conf: model_localize_execute_comand(state.conf, action)
      }
    default:
      return state;
  }
}

function chekedModel(state=init.chekedModel, action) {
  switch(action.type) {
    case MODEL_LOCALIZE_EXECUTE_COMAND:
      return [];
    case MODEL_LOCALIZE_ON_CHACKED:
      if(state.includes(action.key)) {
        return state.filter(el => el !== action.key)
      }
      return [
        ...state,
        action.key
      ] 
    default:
      return state;
  }
}

function onChange(state, action) {
  console.log(state);
  const obj = action.path.reduce((obj, key) => (obj.hasOwnProperty(key)) ? obj[key] : obj, state);

  obj[action.key] = action.value;

  return {
    ...state,
  };
}

function model_localize_execute_comand(state={}, action) {
  switch(action.comand) {
    case 'delete_group': {
      
      delete state.groups[action.params.group_name];
      return {
        ...state,
        groups: {
          ...state.groups
        }
      } 
    }
    case 'delete_model':
      action.keys.forEach(key => delete state.model[key]);
      return {
        ...state,
        model: {
          ...state.model
        }
      } 
    case 'delete_model_all':
      return {
        ...state,
        model: {}
      }
    case 'add_group': {
      if(!state.hasOwnProperty('group_number')) {
        state.groups = {0: 'no group'};
        state.group_number = 1;
      }

      for(let key in state.groups) {
        if(state.groups[key] === action.params.group_name) {
          return state;
        }
      }

      return {
        ...state,
        group_number: Number(state.group_number) + 1,
        groups: {
          ...state.groups,
          [state.marka_id +""+state.group_number]:action.params.group_name
        }
      }
    }
    case 'move_group': {
      const model = {};

      action.keys.forEach(key => model[key] = {
        ...state.model[key],
        group_id: action.params.group_name,
        group_name: state.groups[action.params.group_name]
      });

      return {
        ...state,
        model: {
          ...state.model,
          ...model
        }
      } 
    }
    case 'on_change_group':
      for(let key in state.model) {
        if(state.model[key].group_id === action.params.group_name) {
          state.model[key].group_name = action.params.new_group_name;
        }
      }

      return {
        ...state,
        groups: {
          ...state.groups,
          [action.params.group_name] : action.params.new_group_name
        }
      };
    default:
      return state;
  }
}

function on_change_groups(state, action) {
  console.log(state, action);
  return state;
}

function localizeDB(state=init.localizeDB, action) {
  switch(action.type) {
    case LOCALIZEDB_TOGGLE:
      return !state;
    default:
      return state;
  } 
}

module.exports = combineReducers({localizeDB, selectMarka, markaList, marka, chekedModel, changedModel});
