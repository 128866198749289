import React from 'react';
import GroupModel from './groupModel.jsx';

export default class GroupModelAccordion extends React.Component {
  constructor() {
    super();

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    const show = event.target.getAttribute('data-key');

    if(show !== null) {
      let newShow = (show === this.props.showGroup) ? null : show;
      this.props.onShowGroup(newShow);
    }
  }

  render() {
    const {groupModels, onChange, onCheked, showGroup} = this.props;
    const elements = [];
       
    for(let key in groupModels) {
      let show = (showGroup === key) ? true : false; 

      elements.push(
        <GroupModel
          key={key}
          title={key}
          models={groupModels[key]}
          show={show}
          onClick={this.handleClick}
          onChange={onChange}
          onCheked={onCheked}
        />
      );
    }

    return(
      <div className="accordion" id="accordionExample">
        {elements}
      </div>
    );
  }
}
