import React from 'react';
import { Input } from '../forms'; 
import ButtonController from './buttonController.jsx';
import Table from './table.jsx';

class SettingsClients extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.onInvalidate('clients');
  }

  handleChange(event) {
    console.log(event.target.name, event.target.value);
    this.props.onChangeForms(event.target.name, event.target.value);
  }

  render() {
    if(this.props.model.isLoading) {
      return <div>Loading ...</div>
    }

    console.log(this.props.model);
    const {name, host, token, last_change} = this.props.form.set;
    return(
      <div>
        <div className="row">
          <div className="col-12 col-lg-3">
            <Input
              label={"name"}
              value={name}
              onChange={this.handleChange}
            />
          </div>
          <div className="col-12 col-lg-5">
            <Input
              label={"host"}
              value={host}
              onChange={this.handleChange}
            />
          </div>
          <div className="col-12 col-lg-4">
            <Input
              label={"last_change"}
              value={last_change}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-12">
            <Input
              label={"token"}
              value={token}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-12">
            <ButtonController
              add={() => this.props.add({name, host})}
              update={() => this.props.update(this.props.form)}
              del={() => this.props.del(this.props.form.where)}
              selected={!(this.props.page.selectedClients === null)}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-lg-12">
            <Table
            exclude={['token', 'last_change']}
            keys={this.props.model.keys}
            items={this.props.model.items}
            active={this.props.page.selectedClients}
            onActive={(id) => this.props.onFormsUpdate(
              {host: this.props.model.items[id].host},
              this.props.model.items[id],
              'selectedClients',
              (this.props.page.selectedClients === id) ? null : id
            )}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default SettingsClients;
