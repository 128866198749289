import React from 'react';
import Input from './input.jsx';

export default class ModelComandAdditional extends React.Component {
  constructor(props) {
    super(props);

    this.handlerOnChange = this.handlerOnChange.bind(this);
    this.handlerOnChangeGroupName = this.handlerOnChangeGroupName.bind(this);
  }

  handlerOnChange(event) {
    const value = event.target.value;
    const { selectComand, onChange } = this.props;

    onChange(selectComand, value);
  }

  handlerOnChangeGroupName(event) {
    const value = event.target.value;
    const { selectComand, onChange } = this.props;

    onChange(selectComand + "_GroupName", value);
  }


  render() {
    const { selectComand, comands, groups, page, onChange } = this.props;

    switch(selectComand) {
      case "add_group":
        return <Input
          className="mb-3" 
          value={(page.hasOwnProperty(selectComand)) ? page[selectComand] : ""}
          placeholder={comands[selectComand]}
          onChange={this.handlerOnChange}
        />
      case "delete_group":
      case "move_group": {
        const options = [];

        for(let key in groups) {
          options.push(<option value={key}>{groups[key]}</option>);
        }

        return (
        <select value={page[selectComand]} onChange={this.handlerOnChange} className="custom-select mb-3">
          {options}
        </select>
        );
      }
      case "on_change_group": {
        const options = [];

        for(let key in groups) {
          options.push(<option value={key}>{groups[key]}</option>);
        }

        return (
        <div className="mb-3">

        <select value={page[selectComand]} onChange={this.handlerOnChange} className="custom-select">
          {options}
        </select>
        <Input
          className="mt-3" 
          value={(page.hasOwnProperty(selectComand + "_GroupName")) ? page[selectComand + "_GroupName"] : ""}
          placeholder={comands[selectComand]}
          onChange={this.handlerOnChangeGroupName}
        />

        </div>
      );
      }
      default:
        return <div></div>
    }
  }
}
