import React from 'react';
import {connect} from 'react-redux';
import { api, apiFetch } from '../../api';
import {Redirect} from 'react-router-dom';

class Item extends React.Component {
  render() {
    var elems = [];

    if(!this.props.item) {
      return <Redirect to="/parser/list"/>
    }

    for(let key in this.props.item) {
      var value = this.props.item[key];
      if(typeof value === 'object' && value !== null) {
        value = JSON.stringify(value); 
      }
      elems.push(
        <div className="form-group">
          <label>{key}</label>
          <input type="text" name="name"
            className="form-control"
            placeholder={key}
            value={value}
            required autofocus="true"
          />
        </div>
      )   
    }

    const pin = Math.ceil(elems.length/2)

    return (
      <div className="row">
        <div className="col-12 mb-3">
          <button
            className="btn btn-danger btn-block"
            onClick={() => {
              let title = 'Вы точно хотите удалить конфигурацию';
              let result = confirm(title);
              if(result) {
                this.props.del({name: this.props.item.name})
              }
          }}>
            Удалить
          </button> 
        </div>
        <div className="col-12 col-lg-5 ">{elems.slice(0, pin)}</div>
        <div className="col-12 col-lg-5">{elems.slice(pin)}</div>
      </div> 
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    item: state.api.models.parser.items[ownProps.match.params.id]
  }
}

const mapDispathToProps = (dispath) => {
  return {
    del: (body) => dispath(apiFetch(api.del, body, 'parser'))
  }
}

export default connect(mapStateToProps, mapDispathToProps)(Item);
