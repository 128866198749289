import React from 'react';

export default (props) => {
  let cn = "row";

  if(props.className) {
    cn += " " + props.className;
  }

  return (<div className={cn}>{props.children}</div>);
}
