import { connect } from 'react-redux';
import Clients from './clients.jsx';
import { api, apiFetch, apiInvalidate } from '../api';
import { formsOnChange, formsSetUpdate } from '../forms';

const mapStateToProps = (state) => {
  return {
    model: state.api.models.clients,
    form: state.forms.clients,
    page: state.page.clients
  }; 
}

const mapDispathToProps = (dispatch, getState) => {
  return {
    add: (data) => dispatch( apiFetch(api.add, data, 'clients') ),
    onInvalidate: (name) => dispatch( apiInvalidate(name) ),
    api: (event, params) => dispatch( apiFetch(api.default(event), params, 'clients' ) ),
    onChangeForms: (name, value) => dispatch( formsOnChange('clients', name, value)),
    onFormsUpdate: (where, set, name, value) => dispatch( formsSetUpdate('clients', where, set, name, value)),
    del: (data) => dispatch( apiFetch(api.del, data, 'clients') ),
    update: (data) => dispatch( apiFetch(api.update, data, 'clients' ) ),
  };
}

export default connect(mapStateToProps, mapDispathToProps)(Clients);
