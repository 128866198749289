const {
  PARSER_CONFIGURE_SET_VALUE,
  PARSER_CONFIGURE_DID_INVALIDATE,
  PARSER_CONFIGURE_SELECT,
  PARSER_CONFIGURE_SELECT_UPDATE,
  PARSER_CONFIGURE_ON_CHANGE,
  PARSER_CONFIGURE_CHECKED_SET,
} = require('./actions');

const PARSER_CONFIGURE_INIT = {
  didInvalidate: true,
  selectParser: null,
  selectParserWhere: [],
  selectParserSet: [],
  parser: {},
}

function selectParserSet(state=[], {name}) {
  if(state.includes(name)) {
    return state.filter(field => field !== name);
  }

  return [
    ...state,
    name
  ]
}

function parser_configure(state=PARSER_CONFIGURE_INIT, action) {
  switch(action.type) {
    case 'API_FEATCH_RESPONSE': {
      if(action.modelName === 'parser') {
        return {
          ...state,
          didInvalidate: true
        }
      }

      return state;
    }
    case PARSER_CONFIGURE_SET_VALUE:
      return {
        ...state,
        selectParser: action.parser.name,
        parser: action.parser,
        didInvalidate: false
      }
    case PARSER_CONFIGURE_DID_INVALIDATE:
      return {
        ...state,
        didInvalidate: true
      }
    case PARSER_CONFIGURE_SELECT:
      return {
        ...state,
        selectParser: action.parserName,
        didInvalidate:  true  
      }
    case PARSER_CONFIGURE_SELECT_UPDATE:
      return {
        ...state,
        selectParserWhere: action.selectParserWhere
      }
    case PARSER_CONFIGURE_ON_CHANGE:
      return {
        ...state,
        parser: {
          ...state.parser,
          [action.name]: action.value
        }
      }
    case PARSER_CONFIGURE_CHECKED_SET:
      return {
        ...state,
        selectParserSet: selectParserSet(state.selectParserSet, action)
      }
    default:
      return state
  }
}

module.exports = parser_configure;
