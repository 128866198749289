const LOCALIZE_FETCH_INVALIDATE = 'LOCALIZE_FEATCH_INVALIDATE';
function localizeInvalidate(modelName, select) {
  return {
    type: LOCALIZE_FETCH_INVALIDATE,
    modelName,
    select
  }
}

const LOCALIZE_FETCH_REQUEST = 'LOCALIZE_FEATCH_REQUEST';
function localizeRequest(modelName, select) {
  return {
    type: LOCALIZE_FETCH_REQUEST,
    modelName,
    select
  }
}

const LOCALIZE_FETCH_RESPONSE = 'LOCALIZE_FEATCH_RESPONSE';
function localizeResponse(modelName, json, select) {
  return {
    type: LOCALIZE_FETCH_RESPONSE,
    items: json.items,
    keys: json.keys,
    modelName,
    select
  }
}

const LOCALIZE_FETCH_ERROR = 'LOCALIZE_FEATCH_ERROR';
function localizeError(error, modelName, select) {
  return {
    type: LOCALIZE_FETCH_ERROR,
    error: error.message,
    modelName,
    select
  }
}

/*
 * @param {function} localize must return Promise
 */
function localizeFetch(api, data, modelName, select, host) {
  return function (dispatch, getState) {
    const { auth }  = getState();

    dispatch(localizeRequest(modelName, select));

    api(auth.token, data, modelName, host) 
      .then(res => {
        if(res.status >= 400) {
          throw Error( res.statusText );
        }

        return res;
      })
      .then(res => res.json())
      .then(json => {
        if(json.error) {
          throw Error( json.error );
        }

        return json;
      })
      .then(data => dispatch(localizeResponse(modelName, data, select)))
      .catch(err => {
        console.log('localizeFetch error: ', err.message);
        dispatch(localizeError(err, modelName, select))
      });

  };
}

module.exports = {
  LOCALIZE_FETCH_INVALIDATE,
  LOCALIZE_FETCH_REQUEST,
  LOCALIZE_FETCH_RESPONSE,
  LOCALIZE_FETCH_ERROR,
  localizeFetch,
  localizeInvalidate
}
