const fetch = require('isomorphic-fetch');

import React from 'react';
import {connect} from 'react-redux';

class Accordion extends React.Component {

  render() {
    return(
      <div className="accordion">
        {this.props.children}
      </div>
    )
  }

}

class Card extends React.Component {
  render() {
    const {title, sum} = this.props;
    return (
      <div className="card">
        <div className="card-header">
          <h2 className="mb-0">
            <button className="btn btn-link" type="button">
              {title} 
            </button>
            <span>{sum}</span>
          </h2>
        </div>

        <div className="collapse show">
          <div className="card-body">
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}

class NumberOfLots extends React.Component {
  constructor() {
    super();

    this.state = {
      items: []
    }
  }

  componentDidMount() {
    fetch('/api/parsers/get/number/of/lots', {
      method: 'GET', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': this.props.token
      }
    })
    .then(res => {
      return res.json()
    })
    .then(result => { 
      return this.setState({items: result.items});
    })
    .catch(err => console.log('NumberOfLOts ERROR:', err));
  }

  render() {
    const { items } = this.state;
    console.log(items);

    if(items.length === 0) {
      return <div>not items</div>
    }

    const cards = items
      .filter(el => el.status)
      .map(el => {
        var sum = 0;
        const lots = el.lots.map(el => {
          sum += Number(el.count);
          return(
            <div>
              <span>{el.marka_name}</span>
              <span>{el.count}</span>
            </div>
          )
        });

        return(
          <Card title={el.name} sum={sum}>
            {lots} 
          </Card>
        );
      });

    return (
      <div>
        <Accordion>
          {cards} 
        </Accordion>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token
  }
}

const mapDispathToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispathToProps)(NumberOfLots);
