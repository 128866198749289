import React from 'react';
import {connect} from 'react-redux';
import { api, apiFetch, apiInvalidate } from '../../api';
import Table from '../../table/table.jsx'; 

class List extends React.Component {

  componentDidMount() {
    if(this.props.hasErrored || this.props.didInvalidate) {
      this.props.onInvalidate('parser');
    }
  }

  render() {
    if(this.props.isLoadign) {
      return <div>Loading</div>
    }

    if(this.props.hasErrored) {
      return (
        <div>
          <p>Has Errored!</p>
          <button className="btn" onClick={this.props.reboot}>reboot</button>
        </div>
      )
    }

    var items = this.props.items.map(el => {
      let {id, name, url, marka} = el;
      return {
        id, name, url, marka
      }
    });

    return (
      <Table body={items}/>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.api.models.parser.isLoading,
    hasErrored: state.api.models.parser.hasErrored,
    items: state.api.models.parser.items,
    didInvalidate: state.api.models.parser.didInvalidate,
  }
}

const mapDispathToProps = (dispatch) => {
  return {
    onInvalidate: (name) => dispatch( apiInvalidate(name) ),
    getAll: () => dispatch( apiFetch(api.all, {}, 'parser')),
    reboot: () => dispatch(parserErroredReboot('parser'))
  }
}

export default connect(mapStateToProps, mapDispathToProps)(List);
