import React from 'react'; 
import ReactDOM from 'react-dom';

import thunk from 'redux-thunk';
import {
  createStore,
  combineReducers,
  applyMiddleware
} from 'redux';
import { Provider } from 'react-redux';

import App from './client/app.jsx';

import { reducer as auth } from './client/modules/auth';
import { reducer as api, subscribe as updateModel  } from './client/modules/api';
import { reducer as forms } from './client/modules/forms';
import { reducer as page } from './client/modules/page';
import { reducer as localizeMarka } from './client/modules/localizeMarka';
import configure from './client/modules/parser/configure/reducer';

import { trackStorForLocalStore, getStateOfLocalStore } from './client/lib/stateForLocalStore';

import { BrowserRouter as Router } from "react-router-dom";

const store = createStore(
  combineReducers({auth, api, forms, page, localizeMarka, configure}),
  getStateOfLocalStore(),
  applyMiddleware(thunk)
);

trackStorForLocalStore(store);
updateModel(store);

store.subscribe(() => console.log(store.getState()));
console.log(store.getState());

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App/>
    </Router>
  </Provider>,
  document.getElementById('root')
);
