import React from 'react';

class Replace extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      add: false,
      value: ""
    }
  }

  render() {
    const {replace, active, onAdd, onDel, onChange, onChangeActive} = this.props;

    return (
      <div className="card mb-3">
        <div className="card-header">
          Удалить подстроку из строки <input onChange={onChangeActive} type="checkbox" checked={active}/>
        </div>

        <div className="collapse show">
          <div className="card-body" style={{"padding": "0px"}}>
            {
              (this.state.add) ? 
                <FormAdd
                  value={this.state.value}
                  onChange={(value) => this.setState(() =>({value}))}
                  onSave={(value) => {
                    this.setState(() => ({
                      add:false,
                      value: ""
                    }));
                    onAdd(value);
                  }}
                /> 
                : null
            }
            {replace.map((text, id) =>
              <div className="input-group">
                <input
                  key={id}
                  name={id}
                  type={"text"}
                  className="form-control"
                  placeholder={text}
                  onChange={({target})=>onChange(id, target.value)}
                  value={text || ''}
                  style={{"border-radius": "0px"}}
                />
                <div className="input-group-append">
                  <button onClick={() => onDel(text)} className="btn btn-outline-danger" style={{"border-radius": "0px"}}>
                    удалить
                  </button>
                </div>
              </div>
            )}
            <button
              className="btn btn-outline-success btn-block"
              onClick={() => this.setState(() => ({add:true}))}
              style={{
                "border-top-left-radius": "0px",
                "border-top-right-radius": "0px"
              }}
            >Добавить</button>
          </div>
        </div>
      </div>
    );
  }
}

function FormAdd({value, onSave, onChange}) {
  return (
    <div className="input-group">
      <input
        key="value"
        name="value"
        type={"text"}
        className="form-control" 
        placeholder="Enter value"
        onChange={({target})=>{onChange(target.value)}}
        value={value || ''}
        style={{"border-radius": "0px"}}
      />
      <div className="input-group-append">
        <button onClick={() => onSave(value)} className="btn btn-outline-success" style={{"border-radius": "0px"}}>
          Сохранить 
        </button>
      </div>
    </div>
  )
}

export default Replace;
