import React from 'react';
import { WraperLogin, SignOut } from './modules/auth';
import { Container, Row, Col } from './modules/grid';
import { BrowserRouter as Router, Route, Link, NavLink, Switch } from "react-router-dom";
import {ParserAdd, ParserConfigure, ParserTemplate, ParserList, ParserItem} from './modules/parser';
import {SettingsClients} from './modules/settings';

import Nav from './modules/nav';

import {Monitoring, MonitoringParser} from './modules/monitoring';
import {LocalizeMarka} from './modules/localizeMarka';
import NumberOfLots from './modules/numberOfLots/numberOfLots.jsx'; 

import LocalizeDBOnConfig from './modules/localizeDBOnConfig/LocalizeDBOnConfig.jsx';

export default function App() {
  return (
    <div className="mb-3">

    <nav className="navbar navbar-light bg-light mb-3">
      <Link className="navbar-brand" to="/">Manager.motoshar.ru</Link>
     <SignOut/> 
    </nav>
    <WraperLogin>
      <Container>
        <Row>
          <Col lg={3} em={12} className="mb-3">
            <Nav/>
          </Col>
          <Col lg={9} em={12}>
            <Route exact path="/" component={Monitoring} />
            <Route path="/parser/add" component={ParserAdd} />
            <Route path="/parser/template" component={ParserTemplate} />
            <Route path="/parser/configure" component={ParserConfigure} />
            <Route path="/localize" component={LocalizeMarka} />
            <Route path="/localizeDB" component={LocalizeDBOnConfig} />
            <Switch>
            <Route path="/monitoring/:id" component={MonitoringParser} />
            <Route path="/monitoring" component={Monitoring} />
            </Switch>
            <Switch>
              <Route path="/parser/list/:id" component={ParserItem} />
              <Route path="/parser/list" component={ParserList} />
            </Switch>
            <Route path="/settings/clients" component={SettingsClients} />
            <Route path="/number/of/lots" component={NumberOfLots} />
          </Col>
        </Row>
      </Container>
    </WraperLogin>

    </div>
  );
}

//const home = () => <div>Home!</div>; 
