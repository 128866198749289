const { 
  FORMS_ON_CHANGE,
  FORMS_SET_UPDATE,
  FORMS_ON_WHERE,
  FORMS_ON_SET
} = require('./actions');

//const initForms = {
//  template: {
//    where: '' || []
//    set: {
//      name: value
//    }
//  }
//}

function set(state={}, action) {
  switch(action.type) {
    case FORMS_ON_CHANGE:
      return {
        ...state,
        [action.name]: action.value
      }
    default:
      return state;
  }
}

function form(state={
  where: {},
  set: {}
}, action) {
  switch(action.type) {
    case FORMS_ON_CHANGE:
      return {
        ...state,
        set: set(state.set, action) 
      }
    case FORMS_SET_UPDATE:
      return {
        ...state,
        set: action.set,
        where: action.where
      }
    case FORMS_ON_WHERE:
      return {
        ...state,
        where: action.where
      }
    case FORMS_ON_SET:
      return {
        ...state,
        set: action.set
      }
    default:
      return state
  }
}

function forms(state={
  template: {
    where: '',
    set: {}
  },
  parser: {
    where: '',
    set: {}
  },
  localizeMarka: {
    where: '',
    set: {}
  },
  clients: {
    where: '',
    set: {}
  }
}, action) {
  switch(action.type) {
    case FORMS_ON_CHANGE:
    case FORMS_SET_UPDATE:
    case FORMS_ON_WHERE:
      return {
        ...state,
        [action.fieldName]: form(state[action.fieldName], action)
      }
    default:
      return state;
  }
}

module.exports = forms;
