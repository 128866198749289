import React from 'react';
import { Redirect } from 'react-router-dom';

function forObj(obj, calback) {
    for(var key in obj) {
      calback(key, obj[key]);
    } 
  }

export default class Table extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      id: null 
    }
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(id) {
    this.setState({redirect: true, id}) 
  }

  renderTHead() {
    if(! this.props.body instanceof Array ) {
      return;
    }

    const elems = [];

    forObj(this.props.body[0], (key) => {
      elems.push(<th>{key}</th>);
    })

    return <tr>{elems}</tr>
  }

  renderTBody() {
    return this.props.body.map((el, id) => {
      let elems = []; 

      forObj(el, (key, val) => elems.push(<td>{val}</td>)) 

      return <tr onClick={() => this.handleClick(id)}>{elems}</tr> 
    });
  }

  render() {
    if(this.state.redirect) {
      return <Redirect to={`/parser/list/${this.state.id}`}/>
    }

    return(
      <table className="table table-hover table-responsive-sm">
        <thead>
          {this.renderTHead()}
        </thead>
        <tbody>
          {this.renderTBody()}
        </tbody>        
      </table>
    )
  }
}
