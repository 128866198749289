require('es6-promise').polyfill();

const fetch = require('isomorphic-fetch');

module.exports = {
  default: api,
  add,
  all,
  update,
  del,
  run,
  stop,
  runParser,
  stopParser,
}

function api(event) {
  return function add(token, data, modelName, host) {
    return fetch( (host||'') + '/api/'+modelName+'/' + event, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Token': token
      },
      body: JSON.stringify(data)
    })
  }
}

function runParser(token, data, modelName, host) {
  return fetch( (host||'') + '/api/client/task/start/parser/'+data.name, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Token': token
    }
  })
} 

function stopParser(token, data, modelName, host) {
  return fetch( (host||'') + '/api/client/task/stop/parser/'+data.name, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Token': token
    }
  })
}

function run(token, data, modelName, host) {
  return fetch( (host||'') + '/api/client/task/run/template/'+data.name, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Token': token
    }
  })
}

function stop(token, data, modelName, host) {
  return fetch( (host||'') + '/api/client/task/stop/template/'+data.name, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Token': token
    }
  })
}

function del(token, data, modelName, host) {
  return fetch( (host||'') + '/api/'+modelName+'/delete', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Token': token
    },
    body: JSON.stringify({where: data})
  })
}

/*
 * {
 *    token,
 *    url,
 *    name
 * }
 */
function add(token, data, modelName, host) {
  return fetch( (host||'') + '/api/'+modelName+'/add', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Token': token
    },
    body: JSON.stringify(data)
  })
}

/*
 * @param {Object} data{
 *  filter,
 *  filds
 * }
 * @param {String} token
 * @return {promise}
 */
function update(token, data, modelName, host) {
  return fetch( (host||'') + '/api/'+modelName+'/update', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Token': token
    },
    body: JSON.stringify(data)
  });
}

/*
 * {token}
 */
function all(token, data, modelName, host) {
  return fetch((host || '') + '/api/'+modelName+'/get/all', {
    method: 'GET',
    headers: {
      'Token': token
    }
  });
}
