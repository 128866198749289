import React from 'react';
import GroupElModel from './groupElModel.jsx';

export default class GroupModel extends React.Component {
  render() {
    const {title, models, show, onClick, onChange, onCheked} = this.props;

    const modelsElem = models.map(el => <GroupElModel onCheked={onCheked} onChange={onChange} {...el}/>); 
    const className = (show) ? "collapse show" : "collapse";

    return (
      <div className="card" onClick={onClick}>
        <div className="card-header" data-key={title}>
          {title}
        </div>

        <div className={className}>
          <div className="card-body">
            {modelsElem}
          </div>
        </div>
      </div>
    );
  }
}
