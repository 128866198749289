const { formsOnChange, formsSetUpdate, formsOnWhere, formsOnSet, FORMS_SET_UPDATE } = require('./actions');
const reducer = require('./reducer');
const InputSelect = require('./inputSelect.jsx').default;
const Input = require('./input.jsx').default;
const Form = require('./form.jsx').default;

module.exports = {
  FORMS_SET_UPDATE,
  formsOnChange,
  formsSetUpdate,
  formsOnWhere,
  formsOnSet,
  reducer,
  Input,
  InputSelect,
  Form
}
