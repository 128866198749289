import React from 'react';

const Input = ({name, value, placeholder, className, type, onChange}) => {
  type        = (type)        ? type        : "text";
  placeholder = (placeholder) ? placeholder : "";
  className = (className) ? "form-control " + className : "form-control";

  if(typeof value === 'boolean') {
    type = "checkbox";
  }

  return (
    <input
      name={name}
      className={className}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />  
  );
}

export default Input;
