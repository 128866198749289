import React from 'react';
import { connect } from 'react-redux';
import { filter, updateLocalizeConf } from './api';
import { localizeFetch, localizeInvalidate } from './fetch';
import { Form } from '../forms';
import { pageOnChange } from '../page';
import {
  MODEL_LOCALIZE_COMANDS,
  localizedb_toggle,
  model_localize_execute_comand,
  model_localize_on_change,
  model_localize_select,
  model_localize_on_change_model,
  model_localize_on_chacked,
  model_localize_set_marka_conf,
  model_localize_conf_localize_onchange,
  model_localize_conf_localize_onchange_key,
  model_localize_conf_localize_delete,
  model_localize_conf_replace_onchange,
  model_localize_conf_replace_delete,
  model_localize_conf_replace_add,
} from './actions';

import Select from './template/select.jsx';
import Input from './template/input.jsx';
import GroupElModel from './template/groupElModel.jsx';
import GroupModel from './template/groupModel.jsx';
import GroupModelAccordion from './template/groupModelAccordion.jsx';
import ButtonControl from './template/buttonControl.jsx';
import ModelComands from './template/modelComands.jsx';
import ModelComandAdditional from './template/modelComandAdditional.jsx';
import Localize from './template/Localize.jsx';
import Replace from './template/Replace.jsx';

class LocalizeMarka extends React.Component {

  constructor(props) {
    super(props);
    //this.timerId = null;
    this.onChangeMarka = this.onChangeMarka.bind(this);
    this.onChangeModel = this.onChangeModel.bind(this);
    this.onDownload = this.onDownload.bind(this); 
    this.onSaveFrom = this.onSaveFrom.bind(this);
    this.onSaveLocalizeConf = this.onSaveLocalizeConf.bind(this);
  }
  
  componentDidMount() {
  }

  onDownload() {
    var marka = this.props.marka.conf;
    var markaString = JSON.stringify(marka, null, 3);
    var filename = marka.name + ".json";
    var file = new Blob([markaString], {type: "application/json"});

    if (window.navigator.msSaveOrOpenBlob) // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename);
    else { // Others
        var a = document.createElement("a"),
                url = URL.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);  
        }, 0); 
    }
  }

  onSaveFrom() {
    var input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.onchange = () => 
      readFile(input, (err, data) => {
        var marka = JSON.parse(data);
        this.props.setMarkaConf(marka.marka_id, marka); 
      });
    input.click();
  }

  onChangeMarka(path) {
    const onChangeMarka = this.props.onChangeMarka;

    return (event) => {
      const target = event.target;
      onChangeMarka(path, target.name, target.value);
    }
  }

  onChangeModel(modelKey) {
    const onChangeModel = this.props.onChangeModel;

    return (event) => {
      const target = event.target;
      onChangeModel(modelKey, target.name, target.value);
    }
  }

  onSaveLocalizeConf() {
    const {changedModel, marka, localizeDB} = this.props;
    const model = {};

    for(let key in changedModel) {
      if(key === 'all' || changedModel[key] === 'delete') {
        model[key] = 'delete';
        continue;
      }
      model[key] = {...marka.conf.model[key], ...changedModel[key]};
    }

    const data = {
      set: { conf: {...marka.conf, model }},
      where: {marka_id: marka.conf.marka_id},
      localizeDB: localizeDB
    };

    this.props.apiFeachUpdateLocalizeConf(data);
  }

  componentWillUnmount() {
    //clearInterval(this.timerId);
  }

  render() {
    const {
      apiFeach,
      onSelectMarka,
      onChekedModel,
      model_comands,
      onExecuteComanda,
      onChangePage,
      onChangeMarka,

      changedModel,
      chekedModel,
      selectMarka,
      markaList,
      marka,

      deleteModel,
      deleteAllModel,

      conf_localize_onchange_key,
      conf_localize_onchange,
      conf_localize_delete,

      conf_replace_onchange,
      conf_replace_add,
      conf_replace_delete,

      localizeDB,
      toggleLocalizeDB,

    } = this.props;
    const { selectedInputSelectMarka, selectComands } = this.props.page;
    const elements = [];

    if(markaList.error) {
      return <div>Error...</div>
    }

    if(markaList.didInvalidate) {
      apiFeach({filter:{select: 'name, marka_id'}}, 'markaList');
    }

    if(marka.didInvalidate && selectMarka !== null) {
      apiFeach({filter:{where:{marka_id: selectMarka}}}, 'marka');
    }

    if(marka.conf.hasOwnProperty('name')) {
      const formData = FilterTypeObj(marka.conf);
      const groupModels = distributeIntoGroups(marka.conf.model, chekedModel, changedModel);

      elements.push(
        <ButtonControl 
          onUpdate={() => this.props.didInvalidate('marka')}
          onSave={this.onSaveLocalizeConf}
          onDownload={this.onDownload}
          onSaveFrom={this.onSaveFrom}
        />
      )

      elements.push(
        <div className="form-group form-check">
          <input type="checkbox" className="form-check-input" id="localizeDBCheck" value={localizeDB} onChange={toggleLocalizeDB}/>
          <label className="form-check-label" htmlFor="localizeDBCheck">Выполнить локализацию базы данных при сохранении</label>
        </div>
      )

      elements.push(
        <Form item={formData.value} onChange={this.onChangeMarka(['conf'])} keys={formData.key}/>
      );

      elements.push(
        <Localize 
          localize={marka.conf.localize} 
          active={marka.conf.model_name_localize}
          onChangeActive={({target}) => onChangeMarka(['conf'],'model_name_localize', Boolean(target.checked))}
          onChange={conf_localize_onchange}
          onChangeKey={conf_localize_onchange_key}
          onDel={conf_localize_delete}
        />
      )

      elements.push(
        <Replace 
          replace={marka.conf.replaces} 
          active={marka.conf.model_name_replace}
          onChangeActive={({target}) => onChangeMarka(['conf'],'model_name_replace', Boolean(target.checked))}
          onChange={(value, newValue) => conf_replace_onchange(value, newValue)}
          onDel={(value) => conf_replace_delete(value)}
          onAdd={(value) => conf_replace_add(value)}
        />
      )

      elements.push(
        <ModelComands 
          page={this.props.page}
          comands={model_comands}
          selectComand={selectComands}
          onChange={onChangePage}
          onExecute={(options) =>
            onExecuteComanda(selectComands, chekedModel, options)
          }
        />
      );

      elements.push(
        <ModelComandAdditional
          page={this.props.page}
          groups={marka.conf.groups}
          comands={model_comands}
          selectComand={selectComands}
          onChange={onChangePage}
        />
      );

      elements.push(
        <GroupModelAccordion 
          onChange={this.onChangeModel}
          onCheked={onChekedModel}
          groupModels={groupModels}
          onShowGroup={(value) => this.props.onChangePage('showGroup', value)}
          showGroup={this.props.page.showGroup}
        />
      );
    }

    return (
      <div>
        <Select
          label={"Марка"}
          value={selectMarka}
          onChange={(value) => onSelectMarka(value, markaList.items.find(el => el.marka_id === value))}
          options={markaList.items.map((el, id) => ({value: el.marka_id, key: el.name + ' - ' + el.marka_id}))}
        />
        {elements}
      </div>
    );
  }
}

function distributeIntoGroups(models, checkeds, changedModel) {
  var groups = {} 

  for (let key in models) {
    const model = (changedModel.hasOwnProperty(key)) ? {
      ...models[key],
      ...changedModel[key]
    } : models[key];

    const modelLet = {
      keyModel: key,
      id: model.model_id,
      name: model.model_name,
      checked: checkeds.includes(key)
    }

    if(groups.hasOwnProperty(model.group_name)) {
      groups[model.group_name].push(modelLet);
    } else {
      groups[model.group_name] = [];
      groups[model.group_name].push(modelLet);
    } 
  }

  return groups;
}

function FilterTypeObj(obj) {
  const keyVal = [];
  const value = {};

  for (let key in obj) {
    if(typeof obj[key] !== 'object') {
      keyVal.push(key);
      value[key] = obj[key];
    }
  }

  return {key: keyVal, value}
}

const mapStateToProps = (state) => {
  return {
    model_comands: MODEL_LOCALIZE_COMANDS,
    marka: state.localizeMarka.marka,
    markaList: state.localizeMarka.markaList,
    selectMarka: state.localizeMarka.selectMarka,
    page: state.page.localizeMarka,
    chekedModel: state.localizeMarka.chekedModel,
    changedModel: state.localizeMarka.changedModel,
    localizeDB: state.localizeMarka.localizeDB
  }
}

const mapDispathToProps = (dispatch) => {
  return {
    setMarkaConf: (marka_id, conf) => dispatch(model_localize_set_marka_conf(marka_id, conf)),
    didInvalidate: (select) => dispatch(localizeInvalidate('localizeMarka', select)),
    deleteModel: (keys) => despatch(localizeFetch(deleteModel, keys)),
    deleteAllModel: () => despatch(localizeFetch(deleteAllModel)),
    apiFeach: (data, select) => dispatch(localizeFetch(filter, data, 'localizeMarka', select)),
    apiFeachUpdateLocalizeConf: (data) => dispatch(localizeFetch(updateLocalizeConf, data, 'localizeMarka', 'updateLocalizeConf')),
    onChangePage: (name, value) => dispatch(pageOnChange('localizeMarka', name, value)), 
    onSave: forms => dispatch( apiFetch( api.update, forms, 'localizeMarka') ),
    onSelectMarka: (marka_id, marka) => dispatch(model_localize_select(marka_id, marka)),
    onChangeMarka: (path, key, value) => dispatch(model_localize_on_change(path, key, value)),
    onChangeModel: (modelKey, key, value) => dispatch(model_localize_on_change_model(modelKey, key, value)),
    onChekedModel: (key) => dispatch(model_localize_on_chacked(key)),
    onExecuteComanda: (comanda, keys, params) => dispatch(model_localize_execute_comand(comanda, keys, params)),

  conf_localize_onchange: (key, value) => dispatch(model_localize_conf_localize_onchange(key, value)),
  conf_localize_delete: (key) => dispatch(model_localize_conf_localize_delete(key)),
  conf_localize_onchange_key: (key, keyNext) => dispatch(model_localize_conf_localize_onchange_key(key, keyNext)),

  conf_replace_onchange: (value, newValue) => dispatch(model_localize_conf_replace_onchange(value, newValue)),
  conf_replace_delete: (value) => dispatch(model_localize_conf_replace_delete(value)),
  conf_replace_add: (value) => dispatch(model_localize_conf_replace_add(value)),
  toggleLocalizeDB: () => dispatch(localizedb_toggle()),
  }
}

function readFile(object, done) { 
  var file = object.files[0] 
  var reader = new FileReader() 
  reader.onload = function() { 
  done(null, reader.result); 
  } 
  reader.readAsText(file);
} 

export default connect(mapStateToProps, mapDispathToProps)(LocalizeMarka);
