import React from 'react'; 

export default (props) => {
  const active = (props.active) ? 'collapse show': 'collapse';
  return(
    <div className={active}>
      <div className="card-body" style={{padding: 0}}>
          {props.children}
      </div>
    </div>
  );
}
