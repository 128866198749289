const {apiFetch} = require('./actions');
const api = require('./api');

module.exports = trackStorForLocalStore;

function trackStorForLocalStore(store) {
  store.subscribe(() => {
    const state = store.getState();
    if(!state.auth.token) {
      return null;
    }
    const models = state.api.models;

    for(let key in models) {
      if(models[key].isLoadding) {
        continue;
      }
      if(models[key].didInvalidate) {
        console.log(key);
        store.dispatch( apiFetch(api.all, {}, key ) );
      }
    }

  })
}
