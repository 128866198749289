import React from 'react';

class InputSelect extends React.Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    if(this.props.didInvalidate) {
      this.props.getItems();
    }
  }

  handleClick(event) {
    const target = event.target;

    if(target.tagName === 'SELECT' ) {
      this.props.onChange(this.props.label,
        [].slice.call(target.selectedOptions)
          .map(el => el.value)
      ) 
    }
    return this.props.onActive( !this.props.active ); 
  }


  renderOptions(options) {
    if(!options) {
      return <option value={null}>Нет опций</option>
    }

    return(
      options.map(({label, value}) => 
        <option value={value}>{label}</option>
    ));
  }

  render() {
    const show = (this.props.active) ? 'show' : '';

    return(
      <div className="input-group mb-3">

        <div className="input-group-prepend">
          <label className="input-group-text">
            {this.props.label}
          </label>
        </div>

        <input value={this.props.value} type="text" className="form-control" placeholder="Select..."/>

        <div className={`input-select input-group-append ${show}`}>
          <button onClick={this.handleClick} className="btn btn-outline-secondary dropdown-toggle" type="button"></button>
          <select multiple value={this.props.value} onChange={this.handleClick} className={`dropdown-menu custom-select ${show}`}>
            {this.renderOptions(this.props.options)}
          </select>
        </div>

      </div>
    )
  }
}

export default InputSelect;
