const PAGE_ON_CHANGE = 'PAGE_ON_CHANGE';

function pageOnChange(fieldName, name, value) {
  return {
    type: PAGE_ON_CHANGE,
    fieldName,
    name,
    value
  }
}

module.exports = {
  PAGE_ON_CHANGE,
  pageOnChange
}
