function filter(token, data, modelName, host) {
  return fetch( (host||'') + '/api/'+modelName+'/filter', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Token': token
    },
    body: JSON.stringify(data)
  })
}

function updateLocalizeConf(token, data, modelName, host) {
  return fetch( (host||'') + '/api/localize/conf/update', {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Token': token
    },
    body: JSON.stringify(data)
  })
}

function deleteModel(token, data, modelName, host) {
  return fetch( (host||'') + '/api/localize/model/delete', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Token': token
    },
    body: JSON.stringify(data)
  })
}

function deleteAllModel(token, data, modelName, host) {
  return fetch( (host||'') + '/api/localize/model/delete/all', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Token': token
    },
    body: JSON.stringify(data)
  })
}

module.exports = {
  filter,
  updateLocalizeConf
}
