import React from 'react';

const Tr = ({item, active, onClick}) => {
  const renderTd = item => {
    const tds = [];
      for(var key in item) {
        if( typeof item[key] === 'string' ) {
          tds.push(<td>{item[key]}</td>);
          continue;
        }
        tds.push(<td>{
          item[key].reduce((sum, current, index, arr) => {
            sum.push(current);

            if( index !== (arr.length-1) ) {
              sum.push(<br/>);
            }

            return sum; 
          }, [])
        }</td>);
      }
    return tds 
  }

  const className = (active) ? 'table-active' : '';

  return <tr onClick={onClick} className={className}>{renderTd(item)}</tr>
}

const Table = ({exclude, onActive, active, keys=[], items=[]}) => {
  if(exclude) {
    keys = keys.filter(el => !exclude.includes(el));
    items = items.map(el => {
      let newEl = {...el};
      exclude.forEach(ex => delete newEl[ex]);
      return newEl;
    })
  }
  return (
    <table className="table table-hover table-responsive-sm">
      <thead>
        <tr>
          { keys.map(key => <th>{key}</th>) }
        </tr>
      </thead>
      <tbody>
        { items.map((item, index) =>
          <Tr
            onClick={() => onActive(index)}
            item={item}
            active={(index === active)}
          />
        )}
      </tbody>
    </table>
  );
}

export default Table;
