import React from 'react';

export default class Select extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    this.props.onChange(Number(target.value));
  }

  options(items) {
    return items.map(item =>
      <option value={item.value}>{item.key}</option>)
  }

  render() {
    const {options, label, value} = this.props;

    return(
    <div className="input-group mb-3">
      <div className="input-group-prepend">
        <label className="input-group-text" for="inputGroupSelect01">{label}</label>
      </div>
      <select
      onChange={this.handleChange}
      value={value}
      className="custom-select">
        {this.options(options)}
      </select>
    </div>
    );
  }
}
