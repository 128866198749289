import React from 'react';
import Login from './login.jsx';
import { Container, Row, Col } from '../../grid';
import { connect } from 'react-redux';
import { actionAuthLogin, actionAuthLogout } from '../actions.js';

class WraperLogin extends React.Component {
  constructor(props) {
    super(props);

    if(!this.props.rememberMe) {
      this.props.logout();
    }

    this.state = {
      userName : '',
      password : '',
      rememberMe : false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

    handleSubmit(event) {
      event.preventDefault();

      this.props.login(this.state);
    };

    handleChange(event) {
        const target = event.target;
        const value = (target.type === 'checkbox') ?
            target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

  render() {
    if(this.props.isAuth) {
      return (
        this.props.children
      );
    }
    return(
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col em={12} lg={4}>
            <Login
              handleSubmit={this.handleSubmit}
              handleChange={this.handleChange}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuth: state.auth.isAuth,
    rememberMe: state.auth.rememberMe
  }
}

const mapDispathToProps = (dispath) => {
  return {
    login: (data) => dispath(actionAuthLogin(data)),
    logout: () => dispath(actionAuthLogout())
  }
}

export default connect(mapStateToProps, mapDispathToProps)(WraperLogin);
