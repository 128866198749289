import React from 'react'; 

export default class Accordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0
    }

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(id) {
    if(this.state.active === id) {
      return this.setState({active: null});
    }
    return this.setState({active: id});
  }

  render() {
    const elem = this.props.children.map( (el, id) => { 
      return {
        ...el,
        props: {
          ...el.props,
          active: (this.state.active === id) ? true : false,
          onActive: () => this.handleClick(id)
        }
      }
    })
    return (
      <div className="accordion">
        {elem}
      </div>
    );
  }
}
