import React from 'react';
import { connect } from 'react-redux';
import Select from './select.jsx';
import fetchLocalizeDB from './fetch';

class LocalizeDBOnConfig extends React.Component {

  constructor() {
    super();

    this.state = {
      isLocalize: false,
      message: null,
      parserName: null,
      markaName: null
      //parserName: this.props.parsers.items[0] || null,
      //markaName: this.props.localizes.items[0] || null
    }
  }

  fetchLocalize() {
    if(!this.props.auth.isAuth) {
        this.setState(() => ({
          message: "Ошибка авторизации",
          isLocalize: false
        }));
      return null;
    }

    this.setState({isLocalize: true});

    const req = {
      token: this.props.auth.token,
      parserName: this.state.parserName,
      markaName: this.state.markaName,
      host: null
    }

    fetchLocalizeDB(req)
    .then(res => {
      console.log(res);
      if(res.status !== 200) {
        this.setState(() => ({
          message: "Ошибка локализыции бд",
          isLocalize: false
        }));
      }

      this.setState(() => ({
        message: "Локализация выполненна",
        isLocalize: false
      }));
    })
    .catch(err => this.setState(() => ({message: "Ошибка локализыции бд"})));
  }

  getOptions(model, callback) {
    if(model.isLoading) {
      return [
        {value: null, key: "Loading..."}
      ]
    }

    if(model.hasError) {
      return [
        {value: null, key: "Error loading..."}
      ]
    }

    return model.items.map(callback);
  }

  render() {
    const {parsers, localizes} = this.props;

    console.log(parsers, localizes);

    const parsersOptions = this.getOptions(parsers, (parser, id) => ({
      value: parser.name, 
      key: parser.name
    }));

    const localizesOptions = this.getOptions(localizes, (localize, id) => ({
      value: localize.name, 
      key: localize.name + " - " + localize.marka_id
    }));

    return (
      <div>
        <Select 
          label={"Парсер"}
          onChange={name => this.setState(() => ({ parserName: name}))}
          options={parsersOptions}
          value={this.state.parserName}
        />
        <Select
          label={"Марка"}
          onChange={name => this.setState(() => ({markaName: name}))}
          options={localizesOptions}
          value={this.state.markaName}
        />
        <p>{`${this.state.parserName} - ${this.state.markaName}`}</p>
        <button className={(this.state.isLocalize) ?
          "btn btn-outline-danger" : "btn btn-success"}
          onClick={() => this.fetchLocalize()}
          disabled={((!this.state.markaName) || (!this.state.parserName) || (this.state.isLocalize))}
        >
          Локализовать
        </button>
        <p>{this.state.message}</p>
      </div>
    ) 
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    parsers: state.api.models.parser,
    localizes: state.localizeMarka.markaList
  }
}

const mapDispathToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispathToProps)(LocalizeDBOnConfig);
