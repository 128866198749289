const FORMS_ON_CHANGE = 'FORMS_ON_CHANGE';
const FORMS_SET_UPDATE = 'FORMS_SET_UPDATE';

function formsOnChange(fieldName, name, value) {
  return {
    type: FORMS_ON_CHANGE,
    fieldName,
    name,
    value
  }
}

const FORMS_ON_WHERE = 'FORMS_ON_WHERE';
function formsOnWhere(fieldName, where) {
  return {
    type: FORMS_ON_WHERE,
    fieldName,
    where
  }
}

const FORMS_ON_SET = 'FORMS_ON_SET';
function formsOnSet(fieldName, set) {
  return {
    type: FORMS_ON_SET,
    fieldName,
    set
  }
}

function formsSetUpdate(fieldName, where, set, name, value) {
  const flag = (value === null);
  return {
    type: FORMS_SET_UPDATE,
    fieldName,
    where: (flag) ? null : where,
    set: (flag) ? {} : set,
    name,
    value
  }
}

module.exports = {
  FORMS_ON_WHERE,
  FORMS_ON_SET,
  FORMS_ON_CHANGE,
  FORMS_SET_UPDATE,
  formsOnChange,
  formsSetUpdate,
  formsOnWhere,
  formsOnSet
}
