module.exports = (req) => {
  const {token, parserName, markaName, host} = req;

  return fetch( (host||'') + '/api/localizeDB/' + parserName + '/' + markaName, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Token': token
    },
  })
}
