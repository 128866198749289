import React from 'react';
import { NavLink } from "react-router-dom";
import {Accordion, Card, CardHeader, CardBody} from '../collapse'

class Nav extends React.Component {
  render() {
    return (
      <Accordion>
        <Card>
          <CardHeader>
            Парсеры 
          </CardHeader>
          <CardBody>
          <ul className="nav nav-pills flex-column">
            <li className="nav-item">
              <NavLink 
                to="/parser/add"
                className="nav-link"
                style={{'border-radius': 0}}
                activeClassName="active">Добавить</NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/parser/list"
                className="nav-link"
                style={{'border-radius': 0}}
                activeClassName="active">Список</NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/parser/configure"
                className="nav-link"
                style={{'border-radius': 0}}
                activeClassName="active">Конфигурирование</NavLink>
            </li>
            <li className="nav-item">
              <NavLink 
                to="/parser/template"
                className="nav-link"
                style={{'border-radius': 0}}
                activeClassName="active">Шаблоны запуска</NavLink>
            </li>
          </ul>
          </CardBody>
        </Card>
        <Card>
          <div className="card-header" style={{padding: 0}}>
            <h5 className="mb-0">
                <NavLink 
                  to="/monitoring"
                  className="btn btn-link"
                  style={{'border-radius': 0}}
                  activeClassName="active">Мониторинг</NavLink>
            </h5>
          </div>
          <CardBody>
          </CardBody>
        </Card>
        <Card>
          <div className="card-header" style={{padding: 0}}>
            <h5 className="mb-0">
                <NavLink 
                  to="/number/of/lots"
                  className="btn btn-link"
                  style={{'border-radius': 0}}
                  activeClassName="active">Заполненность базы данных</NavLink>
            </h5>
          </div>
          <CardBody>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            Локализация 
          </CardHeader>
          <CardBody>
          <ul className="nav nav-pills flex-column">
            <li className="nav-item">
              <NavLink 
                to="/localize"
                className="nav-link"
                style={{'border-radius': 0}}
                activeClassName="active">Конфигурирование</NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/localizeDB"
                className="nav-link"
                style={{'border-radius': 0}}
                activeClassName="active">Локализовать DB</NavLink>
            </li>
          </ul>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>Настройки</CardHeader>
          <CardBody>
            <ul className="nav nav-pills flex-column">
              <li className="nav-item">
                <NavLink 
                  to="/settings/clients"
                  className="nav-link"
                  style={{'border-radius': 0}}
                  activeClassName="active">Клиенты</NavLink>
              </li>
            </ul>
          </CardBody>
        </Card>
      </Accordion>
    );
  }
}

export default Nav;
