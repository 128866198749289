import React from 'react';
import {connect} from 'react-redux';
import { api, apiFetch, apiInvalidate } from '../api';

const ButtonRuninng = ({running, run, stop}) => {
  if(running) {
    return (
      <button onClick={stop} className="btn btn-outline-danger">
        Остановить
      </button>
    );
  }

  return (
    <button onClick={run} className="btn btn-outline-success">
      Запустить 
    </button>
  );
}

const ProgressBar = ({title, min, max}) => {
  const current = max - min;
  const progress = (max) ? min * 100 / max : 0;
  return(
    <div className="mt-3">
      <p style={{"margin-bottom": 0}}>{title}</p>
      <div className="progress">
        <div className="progress-bar"
          role="progressbar"
          style={{width: `${progress}%`}}
          aria-valuenow={min}
          aria-valuemin="0"
          aria-valuemax={max}>{`${min} / ${max}`}</div>
      </div>
    </div>
  );
} 

class Progress extends React.Component {

  constructor(props) {
    super(props);
    this.timerId = null;
    this.state = {
      error: null,
      isLoaded: false,
      stat: {} 
    };
  }

  fetchGetProgress(parserName, token) {
    console.log(token);
    fetch('/api/parser/'+parserName+'/get/progress', {
      method: 'GET',
      headers: {
        'Token': token
      }
    })
   .then(res => res.json())  
   .then((result) => {
          this.setState({
            isLoaded: true,
            stat: result,
            error: null
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }
  
  componentDidMount() {
    const parserName = this.props.parser.name;
    const token = this.props.token;

    console.log('Progress: ', parserName, token);

    this.fetchGetProgress(parserName, token);
    this.timerId = setInterval(() => this.fetchGetProgress(parserName, token), 5000);
  }

  componentWillUnmount() {
   clearInterval(this.timerId);
  }

  render() {
    const {run, stop, running} = this.props;
    const {name, datestart, datestop} = this.props.parser;
    const {stat, isLoaded} = this.state;
    const progress = [];
    const progressNull = [];
    var error = 0;

    const marka = {
      title: 'Марка:',
      min: 0,
      max: 0,
    }
    const queue = {
      title: 'Марки в очереди',
      min: 0,
      max: 0,
    }

    if(isLoaded && stat.status) {
      marka.title = `Марка: ${stat.markaName}`; 
      marka.max = stat.markaMax;
      marka.min = stat.markaMin;

      queue.max = stat.queueMax;
      queue.min = stat.queueMin;

      if(stat.error instanceof Array) {
        error = stat.error.length;
      } else {
        for(let key in stat.error) {
          error++;
        }
      }
    }

    if(isLoaded) {
      for(let key in stat.progress) {
        if(stat.progress[key] === null) {
          progressNull.push(
            <p className="mt-3">{key}: Марка ненайдена</p>);
          continue;
        }
        progress.push(<ProgressBar 
          title={key}
          min={stat.progress[key][0]}
          max={stat.progress[key][1]}
        />)
      }
    }
    console.log('parser: ', this.state);
    return (
      <div>
        <div class="card">
          <div class="card-header text-center">
            {name}
          </div>
          <div class="card-body">
            <div className="row"> 
              <div className="col">
                <ButtonRuninng
                  stop={() => stop({name})}
                  run={() => run({name})}
                  running={stat.status || running}
                />
              </div>
              <div className="col">
                <p>Ошибок: {error}</p>
              </div>
            </div>
            <ProgressBar
            {...marka}
            />
            <ProgressBar
            {...queue}
            />
            {progress}
            {progressNull}
          </div>
          <div class="card-footer text-muted">
            Запущен: {datestart} / Остановлен: {datestop} 
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    token: state.auth.token,
    parser: state.api.models.parser.items.find(el => ownProps.match.params.id === el.name)
  }
}

const mapDispathToProps = (dispatch) => {
  return {
    onInvalidate: (name) => dispatch( apiInvalidate(name) ),
    run: (data) => dispatch( apiFetch(api.runParser, data, 'parser')),
    stop: (data) => dispatch( apiFetch(api.stopParser, data, 'parser'))
  }
}

export default connect(mapStateToProps, mapDispathToProps)(Progress);
