const API_FETCH_INVALIDATE = 'API_FEATCH_INVALIDATE';
function apiInvalidate(modelName) {
  return {
    type: API_FETCH_INVALIDATE,
    modelName
  }
}

const API_FETCH_REQUEST = 'API_FEATCH_REQUEST';
function apiRequest(modelName) {
  return {
    type: API_FETCH_REQUEST,
    modelName,
  }
}

const API_FETCH_RESPONSE = 'API_FEATCH_RESPONSE';
function apiResponse(modelName, json) {
  return {
    type: API_FETCH_RESPONSE,
    items: json.items,
    keys: json.keys,
    modelName
  }
}

const API_FETCH_ERROR = 'API_FEATCH_ERROR';
function apiError(error, modelName) {
  return {
    type: API_FETCH_ERROR,
    error: error.message,
    modelName
  }
}

/*
 * @param {function} api must return Promise
 */
function apiFetch(api, data, modelName, host) {
  return function (dispatch, getState) {
    const { auth }  = getState();

    dispatch(apiRequest(modelName));

    api(auth.token, data, modelName, host) 
      .then(res => res.json())
      .then(json => {
        if(json.error) {
          throw Error( json.error );
        }

        return json;
      })
      .then(data => dispatch(apiResponse(modelName, data)))
      .catch(err => {
        console.log('apiFetch error: ', err.message);
        dispatch(apiError(err, modelName))
      });

  };
}

module.exports = {
  API_FETCH_INVALIDATE,
  API_FETCH_REQUEST,
  API_FETCH_RESPONSE,
  API_FETCH_ERROR,
  apiFetch,
  apiInvalidate
}
