import React from 'react'; 

export default (props) => {
  return(
    <div className="card-header" style={{padding: 0}}>
      <h5 className="mb-0">
        <button onClick={props.onActive} className="btn btn-link" type="button">
          {props.children}
        </button>
      </h5>
    </div>
  );
}
