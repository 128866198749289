import React from 'react'; 

export default (props) => {
    const elem = props.children.map( (el, id) => { 
      if(id === 0) {
        return {
          ...el,
          props: {
            ...el.props,
            onActive: props.onActive
          }
        }
      }
      if(id === 1) {
        return {
          ...el,
          props: {
            ...el.props,
            active: props.active,
          }
        }
      }
      return el;
    })
  return(
    <div className="card">
      {elem}
    </div>
  );
}
