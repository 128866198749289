import React from 'react';
import { connect } from 'react-redux';
import { api, apiFetch } from '../../api';

import {
  parser_configure_set_value,
  parser_configure_did_invalidate,
  parser_configure_select,
  parser_configure_select_update,
  parser_configure_on_change,
  parser_configure_checked_set,
} from './actions';

const InputGroup = ({onChange, label, value, type="text"}) => {
  if(typeof value === 'object') {
    value = JSON.stringify(value);
  }
  return (
    <div className="input-group mb-3">
      <div className="input-group-prepend">
        <span className="input-group-text" id={label}>{label}</span>
      </div>
      <input
        name={label}
        type={type} 
        className="form-control" 
        placeholder={label} 
        aria-label={label} 
        aria-describedby={label}
        onChange={onChange}
        value={value}
      />
    </div>
  );
}

const InputGroupCheched = ({onChange, label, value, type="text", checked, onChecked}) => {
  if(typeof value === 'object') {
    value = JSON.stringify(value);
  }
  return (
    <div className="input-group mb-3">
      <div className="input-group-prepend">
        <span className="input-group-text" id={label}>{label}</span>
        <div className="input-group-text">
          <input
            type="checkbox"
            checked={checked}
            onChange={onChecked}
          />
        </div>
      </div>
      <input
        name={label}
        type={type} 
        className="form-control" 
        placeholder={label} 
        aria-label={label} 
        aria-describedby={label}
        onChange={onChange}
        value={value}
      />
    </div>
  );
}

const Form = ({ onChange, keys, item={}, exclude=[], selectParserSet, parserConfigureCheckedSet }) => {
  const elems = keys
    .filter(key => !exclude.includes(key))
    .map((key, id) => {
    return (
      <InputGroupCheched
        onChange={onChange}
        key={id}
        label={key}
        value={item[key] || ""}
        checked={selectParserSet.includes(key)}
        onChecked={() => parserConfigureCheckedSet(key)}
      />
    )
  });
  const pin = Math.ceil(elems.length/2);

  return (
      <div className="row">
        <div className="col-12 col-lg-6 ">{elems.slice(0, pin)}</div>
        <div className="col-12 col-lg-6">{elems.slice(pin)}</div>
      </div> 
  )
}

const Options = ({options}) => {
  if(!options.length) {
    return <option>нету опций</option>
  }

  return options.map( ({value, label}, id) =>
        <option key={id} value={value||id}>{label}</option> )
}

const Select = ({onChange, options, selected, name, multiple=false}) => {
  return (
    <select
      className="form-control"
      onChange={onChange} 
      multiple={multiple}
      value={selected}
      name={name}
    >
      <Options options={options} />
    </select>
  );
}

class Configure extends React.Component {
  constructor(props) {
    super(props);

    this.handleChangeItem = this.handleChangeItem.bind(this);
    this.handleParserSelect = this.handleParserSelect.bind(this);
    this.handleParserSelectUpdate = this.handleParserSelectUpdate.bind(this);
    this.handleUpgradeParser = this.handleUpgradeParser.bind(this);
  }

  componentDidMount() {
    return this.props.getAll();
  }

  handleParserSelect(event) {
    const name = event.target.value;
    this.props.parserConfigureSelect(name);
  }

  handleParserSelectUpdate(event) {
    const value = [].slice.call(event.target.selectedOptions)
      .map(el => el.value);

    return this.props.parserConfigureSelectUpdate(value);
  }

  handleChangeItem(event) {
    return this.props.parserConfigureOnChange(
      event.target.name,
      event.target.value,
    )
  }

  handleUpgradeParser() {
    const set = {};
    const {
      parser,
      selectParserSet,
      selectParserWhere,
      onUpgrade
    } = this.props;

    if(selectParserWhere.length === 0) return null;

    selectParserSet.forEach(key => set[key] = parser[key]);

    const forms = {
      set,
      where: {name: selectParserWhere}
    }

    onUpgrade(forms);
  }

  render() {
    const { items, keys,
      configureDidInvalidate,
      configure, parser,
      selectParser, selectParserWhere,
      selectParserSet,
      configureSetValue,
    } = this.props;

    if(this.props.isLoading) {
      return(
        <div>Loading ... </div>
      )
    }
    const item = items.find(({name}) => name === selectParser) || items[0];

    if(!item) {
      return(
        <div>Init ... </div>
      )
    }

    if(configureDidInvalidate) {
      configureSetValue(item);
      return(
        <div>Init ... </div>
      )
    }


    const parserList = items.map( (el, id) => ({
      value: el.name,
      label: `${el.name}`
    }) );

    return(
      <div>

        <div className="row">
          <div className="col-12 col-lg-6">
              <Select name="item" 
                selected={selectParser}
                options={parserList}
                onChange={this.handleParserSelect}
              />
          </div>
          <div className="col-12 col-lg-6">
            <button className="btn btn-succes">Добавить</button>
          </div>
        </div>
        <div className="row  mt-3">
          <div className="col">
            <InputGroup
              key={'name'}
              label={'name'}
              value={item.name}
              onChange={this.handleChangeItem}
            />
          </div> 
        </div>
        <div className="row">
          <div className="col">
            <InputGroup
              key={'token'}
              label={'token'}
              value={item.token}
            />
          </div>
          <div className="col">
            <InputGroup
              key={'last_change'}
              label={'last_change'}
              value={item['last_change']}
            />
          </div> 
        </div>
        <Form item={parser} keys={keys}
          parserConfigureCheckedSet={this.props.parserConfigureCheckedSet}
          selectParserSet={selectParserSet}
          onChange={this.handleChangeItem}
          exclude={[
            'token', 'last_change', 'status_register', 'name'
          ]}
        />
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="form-group">
              <label>{'Пременить конфигурацию к:'}</label>
              <Select name="upgrade"
                options={parserList}
                selected={selectParserWhere}
                onChange={this.handleParserSelectUpdate}
                multiple={true}
              />
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <button className="btn btn-success" onClick={this.handleUpgradeParser}>Сконфигурировать</button>
            <button className="btn btn-danger" onClick={this.props.getAll}>Сбросить</button>
          </div>
        </div>
      </div>
    );
  }
}

function filterObj(obj, exclude) {
  const filterd = {};

  for (var key in obj) {
    if(! exclude.includes(key)) {
      filterd[key] = obj[key];
    }
  }

  return filterd;
}

const mapStateToProps = (state) => {
  return {
    keys: state.api.models.parser.keys,
    items: state.api.models.parser.items,
    didInvalidate: state.api.models.parser.didInvalidate,
    isLoading: state.api.models.parser.isLoading,
    hasErrored: state.api.models.parser.hasErrored,

    parser: state.configure.parser,
    selectParser: state.configure.selectParser,
    selectParserWhere: state.configure.selectParserWhere,
    selectParserSet: state.configure.selectParserSet,
    configureDidInvalidate: state.configure.didInvalidate
  }
}

const mapDispathToProps = (dispatch, getState) => {
  return {
    getAll: () => dispatch( apiFetch( api.all, {}, 'parser') ),
    onUpgrade: forms => dispatch( apiFetch( api.update, forms, 'parser') ),
    configureSetValue: (value) => dispatch(parser_configure_set_value(value)),
    parserConfigureSelect: (name) => dispatch(parser_configure_select(name)),
    parserConfigureSelectUpdate: (value) => dispatch(parser_configure_select_update(value)),
    parserConfigureOnChange: (name, value) => dispatch(parser_configure_on_change(name, value)),
    parserConfigureCheckedSet: (name) => dispatch(parser_configure_checked_set(name))
  }
}

          //<div className="col">
          //  <InputGroup
          //    key={'url'}
          //    label={'url'}
          //    value={item.url}
          //  />
          //</div> 

export default connect(mapStateToProps, mapDispathToProps)(Configure);
