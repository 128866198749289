const AUTH_QUERY = 'AUTH_QUERY';
const AUTH_SUCCESS = 'AUTH_SUCCESS';
const AUTH_FAIL = 'AUTH_FAIL';
const AUTH_LOGOUT = 'AUTH_LOGOUT';

module.exports = {
  AUTH_QUERY,
  AUTH_SUCCESS,
  AUTH_FAIL,
  AUTH_LOGOUT,
  actionAuthLogin,
  actionAuthLogout
}

function actionAuthQuery() {
  return {
    type: AUTH_QUERY
  }
}

function actionAuthSuccess(token, rememberMe) {
  return {
    type: AUTH_SUCCESS,
    token,
    rememberMe
  }
}

function actionAuthFail() {
  return {
    type: AUTH_FAIL
  }
}

function actionAuthLogin(data, host) {
  return distpath => {

    distpath(actionAuthQuery());

    return fetch((host || '') + '/signin', {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(res => {
        return res.json()
      })
      .then(result => { 
        if(result.token === null) {
          return distpath( actionAuthFail() );
        }

        return distpath(
          actionAuthSuccess(result.token, data.rememberMe)
        );
      })
  }
}

function actionAuthLogout() {
  return {
    type: AUTH_LOGOUT
  }
}
