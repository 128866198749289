import React from 'react';

class Localize extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      add: false,
      key: "",
      value: ""
    }
  }

  render() {
    const {localize, active, onChangeActive, onChange, onChangeKey, onAdd, onDel} = this.props;

    return (
      <div className="card mb-3">
        <div className="card-header">
          Заменить подстроку на строку <input onChange={onChangeActive} type="checkbox" checked={active}/>
        </div>

        <div className="collapse show">
          <div className="card-body" style={{"padding": "0px"}}>
            {
              (this.state.add) ? 
                <FormAdd
                  keyValue={this.state.key}
                  value={this.state.value}
                  onChange={(name, value) => this.setState(() =>({[name]: value}))}
                  onSave={(key, value) => {
                    this.setState(() => ({
                      add:false,
                      key: "",
                      value: ""
                    }));

                    onChange(key, value);
                  }}
                /> 
                : null
            }
            {
              ObjectForich(localize, (key, value) =>
                <FormaLocalize 
                  key={key}
                  valueKey={key}
                  value={value}
                  onDel={onDel}
                  onChange={onChange}
                  onChangeKey={onChangeKey}
                />
              )
            }
            <button
              onClick={() => this.setState(() => ({add:true}))}
              className="btn btn-outline-success btn-block"
              style={{
                "border-top-left-radius": "0px",
                "border-top-right-radius": "0px"
              }}
            >Добавить</button>
          </div>
        </div>
      </div>
    );
  }
}

function FormaLocalize({valueKey, value, onChange, onChangeKey, onDel}) {
  let key = valueKey;

  return (
    <div className="input-group">
      <input
        key={key}
        name={key}
        type={"text"}
        className="form-control" 
        placeholder={key} 
        onChange={({target})=>{onChangeKey(key, target.value)}}
        value={key || ''}
        style={{"border-radius": "0px"}}
      />
      <input
        key={value}
        name={value}
        type={"text"}
        className="form-control" 
        placeholder={value} 
        onChange={({target})=>{onChange(key, target.value)}}
        value={value || ''}
        style={{"border-radius": "0px"}}
      />
      <div className="input-group-append">
        <button onClick={() => onDel(key)} className="btn btn-outline-danger" style={{"border-radius": "0px"}}>
          удалить
        </button>
      </div>
    </div>
  );
}

function FormAdd({keyValue, value, onSave, onChange}) {
  return (
    <div className="input-group">
      <input
        key="NevKeyValue"
        name="key"
        type={"text"}
        className="form-control" 
        placeholder="Enter key"
        onChange={({target})=>{onChange("key", target.value)}}
        value={keyValue || ''}
        style={{"border-radius": "0px"}}
      />
      <input
        key="NevValue"
        name="value"
        type={"text"}
        className="form-control" 
        placeholder="Enter value"
        onChange={({target})=>{onChange("value", target.value)}}
        value={value || ''}
        style={{"border-radius": "0px"}}
      />
      <div className="input-group-append">
        <button onClick={() => onSave(keyValue, value)} className="btn btn-outline-success" style={{"border-radius": "0px"}}>
          Сохранить 
        </button>
      </div>
    </div>
  )
}

function ObjectForich(obj, callback) {
  var result = [];

  for(let key in obj) {
    result.push(callback(key, obj[key]));
  }

  return result; 
}

export default  Localize;
