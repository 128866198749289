const { combineReducers } = require('redux');
const {
  API_FETCH_INVALIDATE,
  API_FETCH_REQUEST,
  API_FETCH_RESPONSE,
  API_FETCH_ERROR
} = require('./actions.js');

const initState = {
  parser: {
    isLoading: false,
    hasErrored: false,
    didInvalidate: true,
    keys: [],
    items: [],
    error: ''
  },
  template: {
    isLoading: false,
    hasErrored: false,
    didInvalidate: true,
    keys: [],
    items: [],
    error: ''
  },
  clients: {
    isLoading: false,
    hasErrored: false,
    didInvalidate: true,
    keys: [],
    items: [],
    error: ''
  }
}

function model(state={
  isLoading: false,
  hasErrored: false,
  didInvalidate: false,
  keys: [],
  items: [],
  error: ''
}, action) {
  switch(action.type) {
    case API_FETCH_INVALIDATE:
      return {
        ...state,
        didInvalidate: true
      }
    case API_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasErrored: false,
        didInvalidate: false,
      }
    case API_FETCH_RESPONSE:
      return {
        ...state,
        isLoading: false,
        didInvalidate: false,
        keys: action.keys,
        items: action.items
      }
    case API_FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
        hasErrored: true,
        error: action.error
      }
    default:
      return state;
  }
}

function models(state=initState, action) {
  switch(action.type) {
    case API_FETCH_INVALIDATE:
    case API_FETCH_REQUEST:
    case API_FETCH_RESPONSE:
    case API_FETCH_ERROR:
      return {
        ...state,
        [action.modelName]: model(state[action.modelName], action)
      }
    default:
      return state
  }
} 

function selected(state='parser', action) {
  switch(action.type) {
    default:
      return state;
  }
}

module.exports = combineReducers({
  selected,
  models
});
