import React from 'react';

const InputGroup = ({onChange, label, value, type="text"}) => {

  return (
    <div className="input-group mb-3">
      <div className="input-group-prepend">
        <span className="input-group-text">{label}</span>
      </div>
      <input
        name={label}
        type={type} 
        className="form-control" 
        placeholder={label} 
        onChange={onChange}
        value={value || ''}
      />
    </div>
  );
}

export default InputGroup;
