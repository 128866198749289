import React from 'react';
import { connect } from 'react-redux';
import { Input, InputSelect, formsOnChange, formsSetUpdate } from '../../../forms';
import { pageOnChange } from '../../../page';
import { api, apiFetch, apiInvalidate } from '../../../api';
import Table from './table.jsx';

const ButtonSelect = ({
  add, update, selected, del, run, stop, runningParser}) => {
  var select1 = (
    <div className="row">

      <div className="col-12 col-lg-6">
        <button onClick={update} className="btn btn-success btn-block">
          Обновить
        </button>
      </div>

      <div className="col-12 col-lg-3">
        <button onClick={del} className="btn btn-danger btn-block">
          Удалить
        </button>
      </div>

      <div className="col-12 col-lg-3">
        <button onClick={(runningParser) ? stop : run} className={`btn btn-${(runningParser) ? 'danger' : 'primary'} btn-block`}>
          {(runningParser) ? 'Остановить' : 'Запустить'}
           
        </button>
      </div>

    </div>
  );
  var select2 = (
    <button onClick={add} className="btn btn-success btn-block">
      Добавить 
    </button>
  )

  return (selected) ? select2 : select1;
}

class Template extends React.Component {
  constructor(props) {
    super(props);
    this.timerId = null;
  }
  
  componentDidMount() {
    this.props.onInvalidate('template');
    
    this.timerId = setInterval(() => this.props.onInvalidate('parser'), 10000);
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  checkRunningParserTemplate() {
    const selected = this.props.forms.where;
    if(!selected) {
      return false;
    }
     
    const findTemplate = this.props.template.items
      .find(el => el.name === selected.name);

    if(!findTemplate) {
      return false;
    }

    const { parsers } = findTemplate;

    const check = this.props.parser.items
      .filter(el => parsers.includes(el.url))
      .some(el => el.running)

    return check
  }

  render() {
    console.log(this.checkRunningParserTemplate())
    return (
      <div>

      <div className="row">
        <div className="col-12 col-lg-3">
          <Input
            label={"name"}
            value={this.props.forms.set.name || ''}
            onChange={(event) => this.props.onChangeForms('name', event.target.value)}
          />
        </div>
        <div className="col-12 col-lg-9">
          <InputSelect
            onChange={this.props.onChangeForms}
            onActive={(value) => this.props.onChangePage('actionInputSelect', value)}
            active={this.props.actionInputSelect}
            didInvalidate={this.props.parser.didInvalidate}
            options={this.props.parser.items.map(el => ({value: el.name, label: el.name + ' - ' + el.url}))}
            getItems={this.props.getItemsParser}
            label={"parsers"}
            value={this.props.forms.set.parsers || ''}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-lg-12">
          <ButtonSelect
            add={() => this.props.addTemplate(this.props.forms.set)}
            update={() => this.props.updateTemplate(this.props.forms)}
            del={() => this.props.deleteTemplate(this.props.forms.where)}
            selected={(this.props.selectedTemplate === null)}
            run={() => this.props.runTemplate(this.props.forms.where)}
            stop={() => this.props.stopTemplate(this.props.forms.where)}
            runningParser={this.checkRunningParserTemplate()}
          />
        </div>
      </div>
      
      <div className="row mt-3">
        <div className="col-12 col-lg-12">
          <Table
            keys={this.props.template.keys}
            items={this.props.template.items}
            active={this.props.selectedTemplate}
            onActive={(id) => this.props.onFormsUpdate(
              {name: this.props.template.items[id].name},
              this.props.template.items[id],
              'selectedTemplate',
              (this.props.selectedTemplate === id) ? null : id
            )}
          />
        </div>
      </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    template: state.api.models.template,
    parser: state.api.models.parser,
    forms: state.forms.template,
    actionInputSelect: state.page.template.actionInputSelect,
    selectedTemplate: state.page.template.selectedTemplate 
 }; 
}

const mapDispathToProps = (dispatch, getState) => {
  return {
    deleteTemplate: (data) => dispatch( apiFetch(api.del, data, 'template') ),
    addTemplate: (data) => dispatch( apiFetch(api.add, data, 'template' ) ),
    updateTemplate: (data) => dispatch( apiFetch(api.update, data, 'template' ) ),
    getItemsTemplate: () => dispatch( apiFetch(api.all, {}, 'template' ) ),
    getItemsParser: () => dispatch( apiFetch(api.all, {}, 'parser' ) ),
    onFormsUpdate: (where, set, name, value) => dispatch( formsSetUpdate('template', where, set, name, value)),
    onChangePage: (name, value) => dispatch( pageOnChange('template', name, value) ),
    onChangeForms: (name, value) => dispatch( formsOnChange('template', name, value)),
    runTemplate: (data) => dispatch( apiFetch(api.run, data, 'parser')),
    stopTemplate: (data) => dispatch( apiFetch(api.stop, data, 'parser')),
    onInvalidate: (name) => dispatch( apiInvalidate(name) )
    
  }
}

export default connect(mapStateToProps, mapDispathToProps)(Template);
